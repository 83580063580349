const caption = (l10n, key, amount) => `${amount} ${l10n[amount > 1 ? `COUNTER_${key}` : `COUNTER_SINGULAR_${key}`]}`;

export default (l10n, priceOptions = []) => {
  if (priceOptions.length === 0) return '- - -';

  const people = {};
  let result = '';

  priceOptions.forEach(({ amount = 1, type }) => {
    const key = type.toUpperCase();
    people[key] = people[key] ? people[key] + amount : amount;
  });

  const keys = Object.keys(people);
  if (keys.length > 1) {
    keys.slice(0, keys.length - 1).forEach((key, index) => {
      result += `${index >= 1 ? ', ' : ''}${caption(l10n, key, people[key])}`;
    });

    const lastKey = keys[keys.length - 1];
    result += ` ${l10n.AND} ${caption(l10n, lastKey, people[lastKey])}`;
  } else {
    result = caption(l10n, keys[0], people[keys[0]]);
  }

  return result;
};
