import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  UserInvoice, AgencyInvoice, Billing, Cancellation, Confirmation, Home, Voucher,
} from './layouts';
import { Dialog, Text } from './reactor/components';
import appContext from './context/appContext';
import { D } from './common';

class Router extends PureComponent {
  handleCloseModal = () => {
    const { context } = this.props;
    context.onData('showErrorModal', false);
  };

  render() {
    const { props: { context: { showErrorModal, errorModalStatus, languageBrowser } }, handleCloseModal } = this;
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              path="/cancellation/:hash"
              render={({ match, ...inherit }) => (
                <Cancellation {...inherit} hash={match.params.hash} />
              )}
            />
            <Route
              path="/confirmation/:hash"
              render={({ match, ...inherit }) => (
                <Confirmation {...inherit} hash={match.params.hash} />
              )}
            />
            <Route
              path="/voucher/:hash"
              render={({ match, ...inherit }) => (
                <Voucher {...inherit} hash={match.params.hash} />
              )}
            />
            <Route
              path="/billing/:hash"
              render={({ match, ...inherit }) => (
                <Billing {...inherit} hash={match.params.hash} />
              )}
            />
            <Route
              path="/agency-invoice/:hash"
              render={({ match, ...inherit }) => (
                <AgencyInvoice {...inherit} hash={match.params.hash} />
              )}
            />
            <Route
              path="/user-invoice/:hash"
              render={({ match, ...inherit }) => (
                <UserInvoice {...inherit} hash={match.params.hash} />
              )}
            />
          </Switch>
        </BrowserRouter>
        <Dialog
          visible={showErrorModal}
          title={languageBrowser !== '' ? D[languageBrowser].ERRORS[`ERROR_${errorModalStatus}`] : null}
          onClose={handleCloseModal}
        >
          <Text>{languageBrowser !== '' ? D[languageBrowser].ERRORS[`ERROR_${errorModalStatus}_SUBTITLE`] : null}</Text>
        </Dialog>
      </div>
    );
  }
}

export default appContext(Router);
