import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import '../../css/components/TextArea.css';

class TextArea extends PureComponent {
  static propTypes = {
    otherOption: func,
  };

  static defaultProps = {
    otherOption: undefined,
  };

  _handleOnChange = (event) => {
    const { otherOption } = this.props;
    otherOption(event.target.value);
  }

  render() {
    const { _handleOnChange, props: { otherOption } } = this;

    return (
      <textarea className="textArea font_body1" onChange={otherOption ? _handleOnChange : null} />
    );
  }
}

export default TextArea;
