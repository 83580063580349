import { D } from '../../common';

export default (lang) => {
  const langDef = 'en-EN';
  const defaultLang = [];
  if (D[lang] === undefined) {
    defaultLang.l10n = D[langDef];
    defaultLang.language = langDef;
  } else {
    defaultLang.l10n = D[lang];
    defaultLang.language = lang;
  }
  return defaultLang;
};
