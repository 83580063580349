import React, { PureComponent } from 'react';
import { string, number } from 'prop-types';
import '../../css/containers/Price.css';
import { Text } from '../../components';
import { C, D } from '../../common';
import appContext, { contextPropTypes } from '../../context/appContext';


class Price extends PureComponent {
  static propTypes = {
    price: number,
    symbol: string,
    className: string,
    context: contextPropTypes,
  };

  static defaultProps = {
    price: undefined,
    symbol: undefined,
    className: undefined,
    context: undefined,

  };

  render() {
    const {
      price,
      symbol,
      className,
      context: { language },
    } = this.props;
    const priceText = price === 0 ? D[language].DIGEST.FREE : `${price}${symbol}`;
    return (
      <div className="price">
        <Text className="since" text={D[language].DIGEST.SINCE} typeText={C.FONTS.FONT_CAPTION_2} />
        <Text className={className} text={priceText} typeText={C.FONTS.FONT_HEADLINE_5} />
      </div>
    );
  }
}

export default appContext(Price);
