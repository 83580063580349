import React, { PureComponent } from 'react';
import {
  bool, string, func, arrayOf, object,
} from 'prop-types';
import { TextArea, Text } from '../../components';
import Modal from '../Modal/Modal';
import { Button, InputOption } from '../../reactor/components';
import '../../css/components/OptionsModal.css';

class OptionsModal extends PureComponent {
  static propTypes = {
    showModal: bool,
    handleCloseModal: func,
    title: string,
    typeText: string,
    data: arrayOf(object),
    onClickOption: func,
    option: string,
    showTextArea: bool,
    saveOption: func,
    buttonText: string,
    buttonColor: string,
    sendOption: func,
    isButtonDisabled: bool,

  };

  static defaultProps = {
    showModal: undefined,
    handleCloseModal: undefined,
    title: undefined,
    typeText: undefined,
    data: undefined,
    onClickOption: undefined,
    option: undefined,
    showTextArea: undefined,
    saveOption: undefined,
    buttonText: undefined,
    buttonColor: undefined,
    sendOption: undefined,
    isButtonDisabled: false,
  };

  render() {
    const {
      showModal,
      handleCloseModal,
      title,
      typeText,
      data,
      onClickOption,
      option,
      showTextArea,
      saveOption,
      buttonText,
      sendOption,
      isButtonDisabled,
      buttonColor,
    } = this.props;

    return (
      <Modal
        className="modalContainer"
        isOpen={showModal}
        onClose={handleCloseModal}
      >
        <div className="optionsContainer">
          <Text
            text={title}
            typeText={typeText}
          />
          <div className="options">
            {data && data.map((item, i) => (
              <InputOption
                key={item.text}
                id={i}
                onChange={() => onClickOption(item.id)}
                value={option === item.id}
                label={item.text}
              />
            ))}
          </div>
          {showTextArea && (<TextArea otherOption={saveOption} />)}
        </div>
        <Button
          title={buttonText}
          onPress={sendOption}
          disabled={isButtonDisabled}
          color={buttonColor}
        />
      </Modal>
    );
  }
}

export default OptionsModal;
