import { THEME } from 'reactor/common';

const {
  BORDER_RADIUS, COLOR, FONT, UNIT, OFFSET, SPACE,
} = THEME;

export default {
  BORDER_RADIUS,

  COLOR: {
    ...COLOR,
    SECONDARY: '#007A75',
    RED: '#fa4472',
  },

  FONT,

  OFFSET,

  SPACE,

  STRIKETHROUGH: {
    alignSelf: 'center',
    borderBottomWidth: 1,
    position: 'absolute',
    width: '100%',
  },

  UNIT,
};
