import React, { PureComponent } from 'react';
import { string, bool } from 'prop-types';
import { stripTags } from '../modules';
import '../../css/components/Text.css';

class Text extends PureComponent {
  static propTypes = {
    typeColor: string,
    typeText: string,
    text: string,
    textHtml: bool,
  };

  static defaultProps = {
    typeColor: undefined,
    typeText: undefined,
    text: undefined,
    textHtml: undefined,
  };

  render() {
    const {
      props: {
        textHtml, text, typeColor, typeText,
      },
    } = this;

    return (
      <div
        style={{ color: typeColor }}
        className={typeText}
      >
        { textHtml ? (
          <div dangerouslySetInnerHTML={stripTags(text)} /> // eslint-disable-line
        ) : (text) }
      </div>
    );
  }
}

export default Text;
