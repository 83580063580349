import React, { PureComponent } from 'react';
import {
  arrayOf,
  shape,
  number,
  string,
} from 'prop-types';
import { Image } from '../../components';
import '../../css/components/Map.css';

class Map extends PureComponent {
  static propTypes = {
    latitude: number,
    longitude: number,
    locations: arrayOf(shape({
      latitude: string,
      longitude: string,
    })),
  };

  static defaultProps = {
    latitude: undefined,
    longitude: undefined,
    locations: undefined,
  };

  getMarkers = (latitude, longitude, locations) => {
    const markers = `&markers=color:green%7Clabel:1%7C${latitude},${longitude}`;
    /*
    if (locations) {
      locations.map((location, i) => {
        markers += `&markers=color:green%7Clabel:${i + 1}%7C${location.latitude},${location.longitude}`;
        return markers;
      });
    }
    */
    return markers;
  };

  render() {
    const {
      props: { latitude, longitude, locations }, getMarkers,
    } = this;
    const markers = getMarkers(latitude, longitude, locations);
    const staticMap = 'https://maps.googleapis.com/maps/api/staticmap'
    + '?size=600x280&maptype=roadmap'
    + `${markers}`
    + '&key=AIzaSyD3TWN6YmJx7AyG7jtoIqG4z0Cyimfpy1g'
    + '&style=visibility:simplified'
    + '&style=feature:poi|visibility:off&style=feature:transit|visibility:off';
    return (
      <div>
        <Image src={staticMap} className="map" />
      </div>
    );
  }
}

export default Map;
