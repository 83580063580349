const AMOUNT = 'amount';

export default (total, fee, discount) => {
  let prices = {};
  if (discount.type === AMOUNT) {
    const taxes = (discount.value * 0.21).toFixed(2);
    prices = {
      total: total + discount.value,
      totalWithDiscount: total,
      agencyCommission: {
        withTaxes: discount.value,
        withoutTaxes: (discount.value - taxes).toFixed(2),
        taxes,
      },
    };
  } else {
    const totalWithoutDiscount = (total / ((100 - discount.value) / 100)).toFixed(2);
    const discountValue = (totalWithoutDiscount - total).toFixed(2);
    const taxes = (discountValue * 0.21).toFixed(2);
    const userTaxes = (total * 0.21).toFixed(2);
    prices = {
      total: totalWithoutDiscount,
      totalWithDiscount: total,
      agencyCommission: {
        withTaxes: discountValue,
        withoutTaxes: (discountValue - taxes).toFixed(2),
        taxes,
      },
      userPrices: {
        withTaxes: total,
        withoutTaxes: (total - userTaxes).toFixed(2),
        taxes: userTaxes,
      },
    };
  }
  return prices;
};
