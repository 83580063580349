import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { Text } from '../../components';
import '../../css/containers/TitleText.css';


class TitleText extends PureComponent {
  static propTypes = {
    typeText: string,
    text: string,
    typeTextTitle: string,
    textTitle: string,
    className: string,
  };

  static defaultProps = {
    typeText: undefined,
    text: undefined,
    typeTextTitle: undefined,
    textTitle: undefined,
    className: undefined,
  };

  render() {
    const {
      className, text, textTitle, typeText, typeTextTitle,
    } = this.props;
    return (
      <div className={`titletext ${className}`}>
        <Text text={textTitle} typeText={typeTextTitle} />
        <Text text={text} typeText={typeText} textHtml />
      </div>
    );
  }
}

export default TitleText;
