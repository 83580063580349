import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { verbosePeople } from '../../BookingPath/containers/BookingPreview/modules';
import { C, colors } from '../../common';
import { fetch, getCancellationKey, getDefaultLang } from '../../components';
import {
  Digest, FeaturedInfo, Footer, HeaderSimple, OptionsModal, TitleText,
} from '../../containers';
import appContext, { contextPropTypes } from '../../context/appContext';
import '../../css/layouts/common.css';
import '../../css/layouts/Cancellation.css';

class Cancellation extends PureComponent {
  static propTypes = {
    hash: string.isRequired,
    context: contextPropTypes,
  };

  static defaultProps = {
    context: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      cancellationReason: '',
      showTextArea: false,
      showDisabledButton: true,
    };
  }

  componentWillMount() {
    const { _getData } = this;
    _getData();
  }

  _processDataCancellation = (response, isCancellationSend) => {
    const { props: { context: { onShowErrorModal, onData } }, _setDataState } = this;
    if (response.errorStatus) {
      onShowErrorModal(response.errorStatus);
    } else {
      const { dates, experience: { limitHoursToCancel }, state } = response;
      const dateNow = new Date();
      const key = getCancellationKey(state, dates[0], limitHoursToCancel, dateNow);
      if (key) _setDataState(key, response);
      if (isCancellationSend) onData('isCancellationSend', true);
    }
  }

  _getData = () => {
    const { props: { hash }, _processDataCancellation } = this;
    const service = `booking/${hash}/get`;
    fetch(service).then((response) => {
      _processDataCancellation(response, false);
    });
  };

  _setDataState = (key, data) => {
    const { props: { context: { onData } } } = this;
    const { prices } = data;
    const defaultLang = getDefaultLang(data.language);
    const { language, l10n } = defaultLang;

    onData('language', language);
    onData('l10n', l10n);
    this.setState({
      pax: verbosePeople(l10n, prices),
      key,
      data,
    });
  };

  _handleModal = (bool) => {
    this.setState({ showModal: bool });
  };

  _updateCancellationReason = (cancellationReason) => {
    const { state: { data }, props: { context } } = this;
    const isOtherOption = cancellationReason === data.cancellationReasons.length.toString();

    this.setState({
      cancellationReason,
      showTextArea: isOtherOption,
      showDisabledButton: isOtherOption,
    });

    context.onData('cancellationReason', cancellationReason);
  };

  _sendCancellation = () => {
    const {
      props: { context: { cancellationReason, textOtherOption }, hash },
      state: { data },
      _processDataCancellation,
    } = this;
    this.setState({ showModal: false });
    const fetchData = { reason: cancellationReason };
    if (cancellationReason === data.cancellationReasons.length.toString()) {
      fetchData.explanation = textOtherOption;
    }
    fetch(`booking/${hash}/cancel`, 'POST', fetchData)
      .then((response) => {
        _processDataCancellation(response, true);
      });
  };

  _saveCancellatioReason = (textOtherOption) => {
    const { context } = this.props;
    this.setState({ showDisabledButton: textOtherOption.length < 10 });
    context.onData('textOtherOption', textOtherOption);
  }

  render() {
    const {
      state: {
        pax, key, data, showModal, showTextArea, showDisabledButton, cancellationReason,
      },
      props: { context: { language, l10n } },
      _handleModal, _saveCancellatioReason, _sendCancellation, _updateCancellationReason,
    } = this;
    return (
      <div>
        <div>
          <HeaderSimple />
          { data && (
            <div className="layout">
              <TitleText
                text={l10n[key].TEXT}
                textTitle={l10n[key].TITLE}
                typeText={C.FONTS.FONT_BODY_1}
                typeTextTitle={C.FONTS.H1}
                className="cancellationTitles"
              />
              <div className="leftLayout">
                <Digest
                  data={data}
                  buttonText={l10n[key].BUTTON}
                  buttonAction={() => _handleModal(true)}
                  bookingActivity={data.experience.name[language]}
                  bookingCode={data.voucherCode}
                  bookingPax={pax}
                  bookingImage={data.experience.image}
                  bookingPrice={data.total.value}
                  bookingSymbol={data.prices[0].symbol}
                  bookingDate={data.dates[0].formatted}
                  bookingAddons={data.addons}
                  bookingExtras={data.extras}
                />
                <OptionsModal
                  showModal={showModal}
                  handleCloseModal={() => _handleModal(false)}
                  title={l10n.CANCELLATION_MODAL.TITLE}
                  typeText={`${C.FONTS.FONT_HEADLINE_6} modalTitle`}
                  data={data.cancellationReasons}
                  onClickOption={_updateCancellationReason}
                  option={cancellationReason}
                  showTextArea={showTextArea}
                  saveOption={_saveCancellatioReason}
                  buttonText={l10n.CANCELLATION_MODAL.BUTTON}
                  sendOption={_sendCancellation}
                  isButtonDisabled={showDisabledButton}
                  buttonColor={colors.primary}
                />
                <TitleText
                  textTitle={l10n.CANCELLATION_LEGAL.TITLE}
                  text={l10n.CANCELLATION_LEGAL.TEXT}
                  typeText={C.FONTS.FONT_BODY_1}
                  typeTextTitle={`${C.FONTS.FONT_HEADLINE_5} cancellationLegalTitle`}
                  className="cancellationTitles"
                />
              </div>
              <div className="rightLayout">
                <FeaturedInfo
                  providerName={data.provider.name}
                  textPhone={data.provider.phone}
                  textMail={data.provider.email}
                />
              </div>
              <Footer />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default appContext(Cancellation);
