import React, { PureComponent } from 'react';
import { shape } from 'prop-types';
import TitleText from '../TitleText/TitleText';
import { D, C } from '../../common';
import appContext, { contextPropTypes } from '../../context/appContext';
import '../../css/containers/NewBooking.css';

class NewBooking extends PureComponent {
  static propTypes = {
    context: contextPropTypes,
    data: shape({}),
  };

  static defaultProps = {
    context: undefined,
    data: undefined,
  };

  _onNewBookingButtonClick = () => {
    const { data } = this.props;
    window.location.href = data.url;
  }

  render() {
    const { props: { context: { language } }, _onNewBookingButtonClick } = this;
    return (
      <div className="newBooking">
        <TitleText
          textTitle={D[language].NEWBOOKING.TITLE}
          typeTextTitle="font_headline5 center"
          text={D[language].NEWBOOKING.TEXT}
          typeText="font_body1 center textNewBooking"
        />
        <button
          type="button"
          className={`newBookingButton ${C.FONTS.FONT_BUTTON_1}`}
          onClick={_onNewBookingButtonClick}
        >
          {D[language].NEWBOOKING.BUTTON}
        </button>

      </div>
    );
  }
}

export default appContext(NewBooking);
