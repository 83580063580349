import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { verbosePeople } from '../../BookingPath/containers/BookingPreview/modules';
import { C, D } from '../../common';
import { fetch, getDefaultLang } from '../../components';
import {
  Digest, FeaturedInfo, HeaderSimple, MeetingPoint, TitleText,
} from '../../containers';
import appContext, { contextPropTypes } from '../../context/appContext';
import '../../css/layouts/Voucher.css';

const { FORM_TIMEOUT } = C;
let TIMEOUT;

class Voucher extends PureComponent {
  static propTypes = {
    hash: string.isRequired,
    context: contextPropTypes,
  };

  static defaultProps = {
    context: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const { _getData } = this;
    _getData();
  }

  componentDidUpdate() {
    const { state: { data }, _printPDF } = this;

    if (data) {
      clearTimeout(TIMEOUT);
      TIMEOUT = setTimeout(() => _printPDF(), FORM_TIMEOUT);
    }
  }

  _printPDF = () => {
    window.print();
  };

  _getData = () => {
    const { props: { context: { onShowErrorModal }, hash }, _setDataState } = this;
    const service = `booking/${hash}/get`;
    fetch(service)
      .then((response) => {
        if (response.errorStatus) {
          onShowErrorModal(response.errorStatus);
        } else {
          _setDataState(response);
        }
      });
  };

  _setDataState = (data) => {
    const { props: { context } } = this;
    const { prices } = data;
    const defaultLang = getDefaultLang(data.language);
    const { language, l10n } = defaultLang;

    context.onData('language', language);
    context.onData('l10n', l10n);

    if (data) {
      this.setState({
        pax: verbosePeople(l10n, prices),
        key: C.VOUCHER,
        data,
        language,
      });
    }
  }

  render() {
    const {
      state: {
        pax, data, key, language,
      }, _printPDF,
    } = this;
    return (
      <div className="voucher">
        <HeaderSimple />
        { data && language && (
        <div className="fullLayout">
          <TitleText
            className="details"
            textTitle={D[language].VOUCHER.TITLE.replace('__NAME__', data.user.name.split(' ')[0])}
            text={D[language].VOUCHER.TEXT}
            typeText={C.FONTS.FONT_BODY_1}
            typeTextTitle={C.FONTS.FONT_HEADLINE_4}
          />
          <Digest
            data={data}
            buttonText={D[language][key].BUTTON}
            buttonAction={_printPDF}
            bookingActivity={data.experience.name[language]}
            bookingCode={data.voucherCode}
            bookingPax={pax}
            bookingImage={data.experience.image}
            bookingPrice={data.total.value}
            bookingSymbol={data.prices[0].symbol}
            bookingTimeLimit={data.experience.limitHoursToCancel}
            bookingDate={data.dates[0].formatted}
            bookingAddons={data.addons}
            bookingExtras={data.extras}
          />
          <TitleText
            className="font_headline6 detailsBooking"
            textTitle={D[language].VOUCHER.DETAILS_TITLE}
            typeTextTitle={C.FONTS.FONT_HEADLINE_5}
            text={data.activityInformation}
            typeText={C.FONTS.FONT_BODY_1}
            textHtml
          />
          <MeetingPoint
            latitude={data.experience.location.lat}
            longitude={data.experience.location.lon}
            address={data.meetingPoint.description}
            description={data.meetingPoint.observation}
            locations={data.meetingPoint.locations}
          />
          <FeaturedInfo
            providerName={data.provider.name}
            textPhone={data.provider.phone}
            textMail={data.provider.email}
          />
        </div>
        )}
      </div>
    );
  }
}

export default appContext(Voucher);
