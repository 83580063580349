import React from 'react';
import {
  string,
  func,
  shape,
  bool,
  number,
} from 'prop-types';
import { Consumer } from './appProvider';

export const contextPropTypes = shape({
  language: string,
  cancellationReason: string,
  textOtherOption: string,
  onData: func,
  showErrorModal: bool,
  errorModalStatus: number,
  languageBrowser: string,
  onShowErrorModal: func,
  isCancellationSend: bool,
});

export default (Component) => {
  const withHOC = props => (
    <Consumer>
      {context => <Component {...props} context={context} />}
    </Consumer>
  );

  withHOC.Component = Component;
  return withHOC;
};
