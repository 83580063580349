import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { Icon, Text } from '../../components';

class IconText extends PureComponent {
  static propTypes = {
    icon: string,
    text: string,
    typeText: string,
    className: string,
  };

  static defaultProps = {
    icon: undefined,
    text: undefined,
    typeText: undefined,
    className: undefined,
  };

  render() {
    const {
      icon,
      text,
      typeText,
      className,
    } = this.props;
    return (
      <div className={className}>
        <Icon className="picture" url={icon} />
        <Text text={text} typeText={typeText} />
      </div>
    );
  }
}

export default IconText;
