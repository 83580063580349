import C from './constants';
import COUNTRIES from '../context/mocks/countries.json';

export default {
  ADDRESS: {
    street: { label: 'l10n.street' },
    number: { label: 'l10n.number', inline: 2 },
    zip: { label: 'l10n.zip', inline: 2 },
    city: { label: 'l10n.city', inline: 2 },
    state: { label: 'l10n.state', inline: 2 },
    country: { label: 'l10n.country', inline: 2 },
  },

  INFO: {
    firstName: { label: 'l10n.name', required: true },
    lastName: { label: 'l10n.surname', required: true },
    country: {
      defaultValue: C.DEFAULT_COUNTRY,
      type: 'select',
      label: 'l10n.country',
      dataSource: Object.values(COUNTRIES).sort(),
      required: true,
    },
    email: {
      keyboard: 'email-address',
      label: 'e-mail',
      required: true,
      hint: 'l10n.email_hint',
    },
    phone: {
      keyboard: 'phone-pad',
      label: 'l10n.phone',
      required: true,
      hint: 'l10n.phone_hint',
    },
  },

  INFO_AFFILIATE: {
    firstName: { label: 'l10n.name', required: true },
    lastName: { label: 'l10n.surname', required: true },
    country: {
      defaultValue: C.DEFAULT_COUNTRY,
      type: 'select',
      label: 'l10n.country',
      dataSource: Object.values(COUNTRIES).sort(),
      required: true,
    },
    email: {
      keyboard: 'email-address',
      label: 'e-mail',
      required: true,
      hint: 'l10n.email_hint',
    },
    phone: {
      keyboard: 'phone-pad',
      label: 'l10n.phone',
      required: true,
      hint: 'l10n.phone_hint',
    },
    memberId: {
      keyboard: 'numeric',
      label: 'l10n.TRAVEL_CLUB_CARD_NUMBER',
      hint: 'l10n.TRAVEL_CLUB_CARD_NUMBER_HINT',
      minChar: 10,
      maxChar: 10,
      required: true,
    },
  },

  INFO_AGENCY: {
    firstName: { label: 'l10n.name', required: true },
    lastName: { label: 'l10n.surname', required: true },
    email: {
      keyboard: 'email-address',
      label: 'l10n.CLIENT_EMAIL',
      required: true,
      hint: 'l10n.CLIENT_EMAIL_HINT',
    },
    country: {
      defaultValue: C.DEFAULT_COUNTRY,
      type: 'select',
      label: 'l10n.country',
      dataSource: Object.values(COUNTRIES).sort(),
      required: true,
    },
    phone: {
      keyboard: 'phone-pad',
      label: 'l10n.phone',
      required: true,
      hint: 'l10n.phone_hint',
    },
    notify: { type: 'option', rounded: false, label: 'l10n.SEND_EMAIL_SUMMARY' },
  },

  COMMENT: {
    comment: { label: 'l10n.comment', lines: 4 },
  },
};
