import { D } from '../../common';

export default (date, format, language) => {
  const formatDate = new Date(date);
  let hours = (formatDate.getHours() < 10) ? `0${formatDate.getHours()}` : formatDate.getHours();
  let minutes = (formatDate.getMinutes() < 10) ? `0${formatDate.getMinutes()}` : formatDate.getMinutes();

  if (typeof date === 'string' && date.search('T') !== -1) {
    [hours, minutes] = date.split('T')[1].split(':');
  }
  return D[language].DATES[format]
    .replace('__YEAR__', formatDate.getFullYear())
    .replace('__DAY__', formatDate.getDate())
    .replace('__MONTH__', D[language].DATES[formatDate.getMonth()])
    .replace('__HOUR__', hours)
    .replace('__MINUTE__', minutes)
    .replace(D[language].DATES.TIME_NULL, '');
};
