export default {
  'es-ES': {
    AND: 'y',
    BILLING: {
      DATA: 'Autofactura Mensual',
      INVOICE: 'Desglose de reservas',
      BILL: 'Nº de orden',
      DATE_RANGE: 'Periodo Facturación',
      DATE: 'Fecha Emisión',
      NOTES: 'Facturación por el destinatario.',
      TOTAL: 'Importe total',
      TAXES: 'IVA 21%',
      COMMISSION: 'Comisión de',
      COLUMNS: {
        DESCRIPTION: 'Descripción',
        NIU: 'NIU',
        TOTAL: 'Importe',
        MINUBE_FEE: 'Comisión minube',
        TOTAL_NET: 'Importe Neto',
      },
    },
    CANCELLATION: {
      TITLE: 'Cancelación de tu reserva',
      TEXT: 'A continuación te mostramos los detalles de tu compra y '
            + 'la política de cancelación. Al cancelar tu reserva te enviaremos'
            + ' un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Cancelar reserva',
      DIALOG_TEXT: 'Cancela gratis hasta  el ',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: '#0e908d',
    },
    CANCELLATION_LEGAL: {
      TITLE: 'Política de cancelación',
      TEXT: 'Puedes consultar el plazo para cancelar tu reserva en el resumen de tu compra que aparece arriba. Si tu reserva se puede cancelar gratuitamente y te encuentras dentro del plazo indicado, recibirás el reembolso del 100% de tu compra. Si te encuentras fuera de plazo para cancelar gratuitamente, tu compra no podrá ser reembolsada. Para más información, consulta nuestra <a href="https://www.minube.com/terminos-y-condiciones">Política de cancelación</a>.',
    },
    CANCELLED: {
      TITLE: 'Actividad cancelada',
      TEXT: 'Tu actividad ya ha sido cancelada',
      BUTTON: null,
      DIALOG_TEXT: 'Reserva cancelada definitivamente el',
      DIALOG_DATE_CANCELLED: true,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },
    CONFIRMATION: {
      TEXT_OK: 'Hemos enviado a __EMAIL__ la confirmación de tu reserva y toda la información para que siempre la tengas disponible. Si no lo encuentras, revisa tu carpeta de spam.',
      TITLE_OK: 'Reserva finalizada con éxito',
      TITLE_CANCEL: 'Cancelación de la actividad',
      TEXT_CANCEL: 'A continuación de mostramos los detalles de tu reserva y la política de cancelación. Si cancelas la reserva te enviaremos un email con toda la confirmación de la cancelación.',
      BUTTON: 'Ver mi reserva',
    },
    CONFIRMATION_EXPIRED: {
      TITLE_OK: 'Lo sentimos, esta reserva ha caducado',
      TEXT_OK: 'El plazo para confirmar tu reserva ha finalizado debido a que esta actividad ya ha tenido lugar.',
    },
    COUNTER_PERSON: 'Personas',
    COUNTER_ADULT: 'Adultos',
    COUNTER_CHILD: 'Niños',
    COUNTER_BABY: 'Bebés',
    COUNTER_SENIOR: 'Jubilados',
    COUNTER_GROUP: 'Grupos',
    COUNTER_STUDENT: 'Estudiantes',
    COUNTER_VEHICLE: 'Vehículos',
    COUNTER_HANDICAPPED: 'Minusválidos',
    COUNTER_OTHER: 'Otros',
    COUNTER_SINGULAR_PERSON: 'Persona',
    COUNTER_SINGULAR_ADULT: 'Adulto',
    COUNTER_SINGULAR_CHILD: 'Niño',
    COUNTER_SINGULAR_BABY: 'Bebé',
    COUNTER_SINGULAR_SENIOR: 'Jubilado',
    COUNTER_SINGULAR_GROUP: 'Grupo',
    COUNTER_SINGULAR_STUDENT: 'Estudiante',
    COUNTER_SINGULAR_VEHICLE: 'Vehículo',
    COUNTER_SINGULAR_HANDICAPPED: 'Minusválido',
    COUNTER_SINGULAR_OTHER: 'Otro',

    DATES: {
      COMPLETE: '__DAY__ de __MONTH__ de __YEAR__ a las __HOUR__:__MINUTE__',
      MONTH_YEAR: '__MONTH__ de __YEAR__',
      TIME_NULL: ' a las 0:0',
      0: 'Enero',
      1: 'Febrero',
      2: 'Marzo',
      3: 'Abril',
      4: 'Mayo',
      5: 'Junio',
      6: 'Julio',
      7: 'Agosto',
      8: 'Septiembre',
      9: 'Octubre',
      10: 'Noviembre',
      11: 'Diciembre',
    },
    DIGEST: {
      TOTAL: 'total',
      CODE: 'Localizador: __CODE__',
      PAX_SINGULAR: '__PAX__ persona',
      PAX_PLURAL: '__PAX__ personas',
      PAX: '__PAX__',
      SINCE: 'Precio total',
      FREE: 'Gratis',
      CANCELLED: 'Cancelada el ',
      CANCELLATION: 'Cancela gratis hasta el',
      BOOKING_NOT_CANCELLABLE: 'Reserva no cancelable',
    },

    EXPIRED: {
      TITLE: 'Actividad caducada',
      TEXT: 'Tu fecha de actividad es anterior a la fecha actual',
      BUTTON: null,
      DIALOG_TEXT: 'Esta actividad ha caducado',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    FEATURED_INFO: {
      TITLE: 'Atención al cliente',
      TEXT: 'Esta actividad es organizada por __NAME__. Puedes contactar con su servicio de atención al cliente a través de:',
      CUSTOMER_PROVIDER: 'Atención al cliente proveedor',
      CUSTOMER_TITLE: 'Si lo prefieres, puedes contactar con minube enviando un e-mail a reservas@minube.com o llamándonos al +34 914 855 578 de lunes a viernes entre las 08:00 y las 18:00 (CET).',
    },
    FOOTER: {
      TEXT: '© minube 2007-2019 la web social de viajes',
    },

    IMPORTANT: {
      TITLE: 'Importante',
    },
    MEETING_POINT: {
      TITLE: 'Punto de encuentro',
    },
    MINUBE: {
      LOGO: 'https://imgs-akamai.mnstatic.com/tools/privateArea/logo_minube_color.svg',
      NAME: 'PRS Marketing Services S.L.',
      ADDRESS: 'Calle Loeches, 65.9',
      CITY: 'Alcorcón, Madrid',
      COUNTRY: 'España',
      ZIP: '28925',
      WEB: 'https://www.minube.com',
      EMAIL: 'info@minube.com',
      PHONE: '914 85 55 78',
      TAX_ID: 'B83737064',
    },
    NEWBOOKING: {
      TITLE: '¿Quieres realizar otra reserva?',
      TEXT: 'Puedes seleccionar otra fecha para realizar la actividad haciendo click aquí.',
      BUTTON: 'Reservar otro día',
    },
    NOTCANCELLABLE: {
      TITLE: 'Plazo de cancelación agotado',
      TEXT: 'Ha terminado el plazo de cancelación gratuita',
      BUTTON: null,
      DIALOG_TEXT: 'Ya no puedes cancelar la actividad',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    VOUCHER: {
      TITLE: '__NAME__, tu reserva se ha realizado con éxito',
      TEXT: '¡Hola!, aquí tienes todos los datos de tu reserva para que los tengas siempre a mano y la información de contacto del organizador. Por favor, comprueba que todos los datos son correctos y... ¡a disfrutar!',
      BUTTON: 'Imprimir',
      DIALOG_TEXT: null,
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: null,
      DETAILS_TITLE: 'Detalles de tu reserva',
      DEFAULT_LINK: 'Ver en el mapa',
    },
    CANCELLATION_MODAL: {
      TITLE: '¿Por qué quieres cancelar tu reserva?',
      BUTTON: 'Cancelar la reserva',
    },
    ERRORS: {
      ERROR_404: 'Página no encontrada',
      ERROR_404_SUBTITLE: 'La página a la que intentas acceder no está disponible actualmente.',
      ERROR_520: 'Se ha producido un error',
      ERROR_520_SUBTITLE: 'Por favor, ponte en contacto con nosotros a través de nuestro tlf o email.',
    },
  },
  'pt-PT': {
    AND: 'y',
    BILLING: {
      DATA: 'Autofactura Mensual',
      INVOICE: 'Desglose de reservas',
      BILL: 'Nº de orden',
      DATE_RANGE: 'Periodo Facturación',
      DATE: 'Fecha Emisión',
      NOTES: 'Facturación por el destinatario.',
      TOTAL: 'Importe total',
      TAXES: 'IVA 21%',
      COMMISSION: 'Comisión de',
      COLUMNS: {
        DESCRIPTION: 'Descripción',
        NIU: 'NIU',
        TOTAL: 'Importe',
        MINUBE_FEE: 'Comisión minube',
        TOTAL_NET: 'Importe Neto',
      },
    },
    CANCELLATION: {
      TITLE: 'Cancelación de tu reserva',
      TEXT: 'A continuación te mostramos los detalles de tu compra y '
            + 'la política de cancelación. Al cancelar tu reserva te enviaremos'
            + ' un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Cancelar reserva',
      DIALOG_TEXT: 'Cancela gratis hasta  el ',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: '#0e908d',
    },
    CANCELLATION_LEGAL: {
      TITLE: 'Política de cancelación',
      TEXT: 'Puedes consultar el plazo para cancelar tu reserva en el resumen de tu compra que aparece arriba. Si tu reserva se puede cancelar gratuitamente y te encuentras dentro del plazo indicado, recibirás el reembolso del 100% de tu compra. Si te encuentras fuera de plazo para cancelar gratuitamente, tu compra no podrá ser reembolsada. Para más información, consulta nuestra Política de cancelación.',
    },
    CANCELLED: {
      TITLE: 'Actividad cancelada',
      TEXT: 'Tu actividad ya ha sido cancelada',
      BUTTON: null,
      DIALOG_TEXT: 'Reserva cancelada definitivamente el',
      DIALOG_DATE_CANCELLED: true,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },
    CONFIRMATION: {
      TEXT_OK: 'Hemos enviado a __EMAIL__ la confirmación de tu reserva y toda la información para que siempre la tengas disponible. Si no lo encuentras, revisa tu carpeta de spam.',
      TITLE_OK: 'Reserva finalizada con éxito',
      TITLE_CANCEL: 'Cancelación de la actividad',
      TEXT_CANCEL: 'A conuniación de mostramos los detalles de tu reserva y la política de cancelación. Si cancelas la reserva te enviaremos un email con toda la confirmación de la cancelación.',
      BUTTON: 'Ver mi reserva',
    },
    CONFIRMATION_EXPIRED: {
      TITLE_OK: 'Lo sentimos, esta reserva ha caducado',
      TEXT_OK: 'El plazo para confirmar tu reserva ha finalizado debido a que esta actividad ya ha tenido lugar.',
    },
    COUNTER_PERSON: 'Personas',
    COUNTER_ADULT: 'Adultos',
    COUNTER_CHILD: 'Niños',
    COUNTER_BABY: 'Bebés',
    COUNTER_SENIOR: 'Jubilados',
    COUNTER_GROUP: 'Grupos',
    COUNTER_STUDENT: 'Estudiantes',
    COUNTER_VEHICLE: 'Vehículos',
    COUNTER_HANDICAPPED: 'Minusválidos',
    COUNTER_OTHER: 'Otros',
    COUNTER_SINGULAR_PERSON: 'Persona',
    COUNTER_SINGULAR_ADULT: 'Adulto',
    COUNTER_SINGULAR_CHILD: 'Niño',
    COUNTER_SINGULAR_BABY: 'Bebé',
    COUNTER_SINGULAR_SENIOR: 'Jubilado',
    COUNTER_SINGULAR_GROUP: 'Grupo',
    COUNTER_SINGULAR_STUDENT: 'Estudiante',
    COUNTER_SINGULAR_VEHICLE: 'Vehículo',
    COUNTER_SINGULAR_HANDICAPPED: 'Minusválido',
    COUNTER_SINGULAR_OTHER: 'Otro',

    DATES: {
      COMPLETE: '__DAY__ de __MONTH__ de __YEAR__ a las __HOUR__:__MINUTE__',
      MONTH_YEAR: '__MONTH__ de __YEAR__',
      TIME_NULL: ' a las 0:0',
      0: 'Enero',
      1: 'Febrero',
      2: 'Marzo',
      3: 'Abril',
      4: 'Mayo',
      5: 'Junio',
      6: 'Julio',
      7: 'Agosto',
      8: 'Septiembre',
      9: 'Octubre',
      10: 'Noviembre',
      11: 'Diciembre',
    },
    DIGEST: {
      TOTAL: 'total',
      CODE: 'Localizador: __CODE__',
      PAX_SINGULAR: '__PAX__ persona',
      PAX_PLURAL: '__PAX__ personas',
      PAX: '__PAX__',
      SINCE: 'Precio total',
      FREE: 'Gratis',
      CANCELLED: 'Cancelada el ',
      CANCELLATION: 'Cancela gratis hasta el',
      BOOKING_NOT_CANCELLABLE: 'Reserva no cancelable',
    },

    EXPIRED: {
      TITLE: 'Actividad caducada',
      TEXT: 'Tu fecha de actividad es anterior a la fecha actual',
      BUTTON: null,
      DIALOG_TEXT: 'Esta actividad ha caducado',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    FEATURED_INFO: {
      TITLE: 'Atención al cliente',
      TEXT: 'Esta actividad es organizada por __NAME__. Puedes contactar con su servicio de atención al cliente a través de:',
      CUSTOMER_PROVIDER: 'Atención al cliente proveedor',
      CUSTOMER_TITLE: 'Si lo prefieres, puedes contactar con minube enviando un e-mail a reservas@minube.com o llamándonos al +34 914 855 578 de lunes a viernes entre las 08:00 y las 18:00 (CET).',
    },
    FOOTER: {
      TEXT: '© minube 2007-2019 la web social de viajes',
    },

    IMPORTANT: {
      TITLE: 'Importante',
    },
    MEETING_POINT: {
      TITLE: 'Punto de encuentro',
    },
    MINUBE: {
      LOGO: 'https://imgs-akamai.mnstatic.com/tools/privateArea/logo_minube_color.svg',
      NAME: 'PRS Marketing Services S.L.',
      ADDRESS: 'Calle Loeches, 65.9',
      CITY: 'Alcorcón, Madrid',
      COUNTRY: 'España',
      ZIP: '28925',
      WEB: 'https://www.minube.com',
      EMAIL: 'info@minube.com',
      PHONE: '914 85 55 78',
      TAX_ID: 'B83737064',
    },
    NEWBOOKING: {
      TITLE: '¿Quieres realizar otra reserva?',
      TEXT: 'Puedes seleccionar otra fecha para realizar la actividad haciendo click aquí.',
      BUTTON: 'Reservar otro día',
    },
    NOTCANCELLABLE: {
      TITLE: 'Plazo de cancelación agotado',
      TEXT: 'Ha terminado el plazo que tenias para la cancelación gratuita',
      BUTTON: null,
      DIALOG_TEXT: 'Ya no puedes cancelar la actividad',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    VOUCHER: {
      TITLE: '__NAME__, tu reserva se ha realizado con éxito',
      TEXT: 'A continuación te mostramos los detalles de tu compra y la política de cancelación. Al cancelar tu reserva te enviaremos un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Imprimir',
      DIALOG_TEXT: null,
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: null,
      DETAILS_TITLE: 'Detalles de tu reserva',
      DEFAULT_LINK: 'Ver en el mapa',
    },
    CANCELLATION_MODAL: {
      TITLE: '¿Por qué quieres cancelar tu reserva?',
      BUTTON: 'Cancelar la reserva',
    },
    ERRORS: {
      ERROR_404: 'Página no encontrada',
      ERROR_404_SUBTITLE: 'La página a la que intentas acceder no está disponible actualmente.',
      ERROR_520: 'Se ha producido un error',
      ERROR_520_SUBTITLE: 'Por favor, ponte en contacto con nosotros a través de nuestro tlf o email.',
    },
  },
  'it-IT': {
    AND: 'y',
    BILLING: {
      DATA: 'Monthly Self-Invoice',
      INVOICE: 'Reservation Breakdown',
      BILL: 'Billing number',
      DATE_RANGE: 'Billing period',
      DATE: 'Date of isssue',
      FEE: 'Commission',
      NOTES: 'Billing by the recipient',
      TOTAL: 'Total',
      TAXES: 'IVA 21%',
      COMMISSION: 'Comisión de',
      COLUMNS: {
        DESCRIPTION: 'Concept',
        NIU: 'ID',
        TOTAL: 'Total',
        MINUBE_FEE: 'Minube Commission',
        TOTAL_NET: 'Net Total',
      },
    },
    CANCELLATION: {
      TITLE: 'Cancelación de tu reserva',
      TEXT: 'A continuación te mostramos los detalles de tu compra y '
            + 'la política de cancelación. Al cancelar tu reserva te enviaremos'
            + ' un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Cancelar reserva',
      DIALOG_TEXT: 'Cancela gratis hasta  el ',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: '#0e908d',
    },
    CANCELLATION_LEGAL: {
      TITLE: 'Política de cancelación',
      TEXT: 'Puedes consultar el plazo para cancelar tu reserva en el resumen de tu compra que aparece arriba. Si tu reserva se puede cancelar gratuitamente y te encuentras dentro del plazo indicado, recibirás el reembolso del 100% de tu compra. Si te encuentras fuera de plazo para cancelar gratuitamente, tu compra no podrá ser reembolsada. Para más información, consulta nuestra Política de cancelación.',
    },
    CANCELLED: {
      TITLE: 'Actividad cancelada',
      TEXT: 'Tu actividad ya ha sido cancelada',
      BUTTON: null,
      DIALOG_TEXT: 'Reserva cancelada definitivamente el',
      DIALOG_DATE_CANCELLED: true,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },
    CONFIRMATION: {
      TEXT_OK: 'Hemos enviado a __EMAIL__ la confirmación de tu reserva y toda la información para que siempre la tengas disponible. Si no lo encuentras, revisa tu carpeta de spam.',
      TITLE_OK: 'Reserva finalizada con éxito',
      TITLE_CANCEL: 'Cancelación de la actividad',
      TEXT_CANCEL: 'A conuniación de mostramos los detalles de tu reserva y la política de cancelación. Si cancelas la reserva te enviaremos un email con toda la confirmación de la cancelación.',
      BUTTON: 'Ver mi reserva',
    },
    CONFIRMATION_EXPIRED: {
      TITLE_OK: 'Lo sentimos, esta reserva ha caducado',
      TEXT_OK: 'El plazo para confirmar tu reserva ha finalizado debido a que esta actividad ya ha tenido lugar.',
    },
    COUNTER_PERSON: 'Personas',
    COUNTER_ADULT: 'Adultos',
    COUNTER_CHILD: 'Niños',
    COUNTER_BABY: 'Bebés',
    COUNTER_SENIOR: 'Jubilados',
    COUNTER_GROUP: 'Grupos',
    COUNTER_STUDENT: 'Estudiantes',
    COUNTER_VEHICLE: 'Vehículos',
    COUNTER_HANDICAPPED: 'Minusválidos',
    COUNTER_OTHER: 'Otros',
    COUNTER_SINGULAR_PERSON: 'Persona',
    COUNTER_SINGULAR_ADULT: 'Adulto',
    COUNTER_SINGULAR_CHILD: 'Niño',
    COUNTER_SINGULAR_BABY: 'Bebé',
    COUNTER_SINGULAR_SENIOR: 'Jubilado',
    COUNTER_SINGULAR_GROUP: 'Grupo',
    COUNTER_SINGULAR_STUDENT: 'Estudiante',
    COUNTER_SINGULAR_VEHICLE: 'Vehículo',
    COUNTER_SINGULAR_HANDICAPPED: 'Minusválido',
    COUNTER_SINGULAR_OTHER: 'Otro',

    DATES: {
      COMPLETE: '__DAY__ de __MONTH__ de __YEAR__ a las __HOUR__:__MINUTE__',
      MONTH_YEAR: '__MONTH__ de __YEAR__',
      TIME_NULL: ' a las 0:0',
      0: 'Enero',
      1: 'Febrero',
      2: 'Marzo',
      3: 'Abril',
      4: 'Mayo',
      5: 'Junio',
      6: 'Julio',
      7: 'Agosto',
      8: 'Septiembre',
      9: 'Octubre',
      10: 'Noviembre',
      11: 'Diciembre',
    },
    DIGEST: {
      TOTAL: 'total',
      CODE: 'Localizador: __CODE__',
      PAX_SINGULAR: '__PAX__ persona',
      PAX_PLURAL: '__PAX__ personas',
      PAX: '__PAX__',
      SINCE: 'Precio total',
      FREE: 'Gratis',
      CANCELLED: 'Cancelada el ',
      CANCELLATION: 'Cancela gratis hasta el',
      BOOKING_NOT_CANCELLABLE: 'Reserva no cancelable',
    },

    EXPIRED: {
      TITLE: 'Actividad caducada',
      TEXT: 'Tu fecha de actividad es anterior a la fecha actual',
      BUTTON: null,
      DIALOG_TEXT: 'Esta actividad ha caducado',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    FEATURED_INFO: {
      TITLE: 'Atención al cliente',
      TEXT: 'Esta actividad es organizada por __NAME__. Puedes contactar con su servicio de atención al cliente a través de:',
      CUSTOMER_PROVIDER: 'Atención al cliente proveedor',
      CUSTOMER_TITLE: 'Si lo prefieres, puedes contactar con minube enviando un e-mail a reservas@minube.com o llamándonos al +34 914 855 578 de lunes a viernes entre las 08:00 y las 18:00 (CET).',
    },
    FOOTER: {
      TEXT: '© minube 2007-2019 la web social de viajes',
    },

    IMPORTANT: {
      TITLE: 'Importante',
    },
    MEETING_POINT: {
      TITLE: 'Punto de encuentro',
    },
    MINUBE: {
      LOGO: 'https://imgs-akamai.mnstatic.com/tools/privateArea/logo_minube_color.svg',
      NAME: 'PRS Marketing Services S.L.',
      ADDRESS: 'Calle Loeches, 65.9',
      CITY: 'Alcorcón, Madrid',
      COUNTRY: 'España',
      ZIP: '28925',
      WEB: 'https://www.minube.com',
      EMAIL: 'info@minube.com',
      PHONE: '914 85 55 78',
      TAX_ID: 'B83737064',
    },
    NEWBOOKING: {
      TITLE: '¿Quieres realizar otra reserva?',
      TEXT: 'Puedes seleccionar otra fecha para realizar la actividad haciendo click aquí.',
      BUTTON: 'Reservar otro día',
    },
    NOTCANCELLABLE: {
      TITLE: 'Plazo de cancelación agotado',
      TEXT: 'Ha terminado el plazo que tenias para la cancelación gratuita',
      BUTTON: null,
      DIALOG_TEXT: 'Ya no puedes cancelar la actividad',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    VOUCHER: {
      TITLE: '__NAME__, tu reserva se ha realizado con éxito',
      TEXT: 'A continuación te mostramos los detalles de tu compra y la política de cancelación. Al cancelar tu reserva te enviaremos un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Imprimir',
      DIALOG_TEXT: null,
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: null,
      DETAILS_TITLE: 'Detalles de tu reserva',
      DEFAULT_LINK: 'Ver en el mapa',
    },
    CANCELLATION_MODAL: {
      TITLE: '¿Por qué quieres cancelar tu reserva?',
      BUTTON: 'Cancelar la reserva',
    },
    ERRORS: {
      ERROR_404: 'Página no encontrada',
      ERROR_404_SUBTITLE: 'La página a la que intentas acceder no está disponible actualmente.',
      ERROR_520: 'Se ha producido un error',
      ERROR_520_SUBTITLE: 'Por favor, ponte en contacto con nosotros a través de nuestro tlf o email.',
    },
  },
  'fr-FR': {
    AND: 'y',
    BILLING: {
      DATA: 'Monthly Self-Invoice',
      INVOICE: 'Reservation Breakdown',
      BILL: 'Billing number',
      DATE_RANGE: 'Billing period',
      DATE: 'Date of isssue',
      FEE: 'Commission',
      NOTES: 'Billing by the recipient',
      TOTAL: 'Total',
      TAXES: 'Taxes 21%',
      COMMISSION: 'Commission of',
      COLUMNS: {
        DESCRIPTION: 'Concept',
        NIU: 'ID',
        TOTAL: 'Total',
        MINUBE_FEE: 'Minube Commission',
        TOTAL_NET: 'Net Total',
      },
    },
    CANCELLATION: {
      TITLE: 'Cancelación de tu reserva',
      TEXT: 'A continuación te mostramos los detalles de tu compra y '
            + 'la política de cancelación. Al cancelar tu reserva te enviaremos'
            + ' un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Cancelar reserva',
      DIALOG_TEXT: 'Cancela gratis hasta  el ',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: '#0e908d',
    },
    CANCELLATION_LEGAL: {
      TITLE: 'Política de cancelación',
      TEXT: 'Puedes consultar el plazo para cancelar tu reserva en el resumen de tu compra que aparece arriba. Si tu reserva se puede cancelar gratuitamente y te encuentras dentro del plazo indicado, recibirás el reembolso del 100% de tu compra. Si te encuentras fuera de plazo para cancelar gratuitamente, tu compra no podrá ser reembolsada. Para más información, consulta nuestra Política de cancelación.',
    },
    CANCELLED: {
      TITLE: 'Actividad cancelada',
      TEXT: 'Tu actividad ya ha sido cancelada',
      BUTTON: null,
      DIALOG_TEXT: 'Reserva cancelada definitivamente el',
      DIALOG_DATE_CANCELLED: true,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },
    CONFIRMATION: {
      TEXT_OK: 'Hemos enviado a __EMAIL__ la confirmación de tu reserva y toda la información para que siempre la tengas disponible. Si no lo encuentras, revisa tu carpeta de spam.',
      TITLE_OK: 'Reserva finalizada con éxito',
      TITLE_CANCEL: 'Cancelación de la actividad',
      TEXT_CANCEL: 'A conuniación de mostramos los detalles de tu reserva y la política de cancelación. Si cancelas la reserva te enviaremos un email con toda la confirmación de la cancelación.',
      BUTTON: 'Ver mi reserva',
    },
    CONFIRMATION_EXPIRED: {
      TITLE_OK: 'Lo sentimos, esta reserva ha caducado',
      TEXT_OK: 'El plazo para confirmar tu reserva ha finalizado debido a que esta actividad ya ha tenido lugar.',
    },
    COUNTER_PERSON: 'Personas',
    COUNTER_ADULT: 'Adultos',
    COUNTER_CHILD: 'Niños',
    COUNTER_BABY: 'Bebés',
    COUNTER_SENIOR: 'Jubilados',
    COUNTER_GROUP: 'Grupos',
    COUNTER_STUDENT: 'Estudiantes',
    COUNTER_VEHICLE: 'Vehículos',
    COUNTER_HANDICAPPED: 'Minusválidos',
    COUNTER_OTHER: 'Otros',
    COUNTER_SINGULAR_PERSON: 'Persona',
    COUNTER_SINGULAR_ADULT: 'Adulto',
    COUNTER_SINGULAR_CHILD: 'Niño',
    COUNTER_SINGULAR_BABY: 'Bebé',
    COUNTER_SINGULAR_SENIOR: 'Jubilado',
    COUNTER_SINGULAR_GROUP: 'Grupo',
    COUNTER_SINGULAR_STUDENT: 'Estudiante',
    COUNTER_SINGULAR_VEHICLE: 'Vehículo',
    COUNTER_SINGULAR_HANDICAPPED: 'Minusválido',
    COUNTER_SINGULAR_OTHER: 'Otro',

    DATES: {
      COMPLETE: '__DAY__ de __MONTH__ de __YEAR__ a las __HOUR__:__MINUTE__',
      MONTH_YEAR: '__MONTH__ de __YEAR__',
      TIME_NULL: ' a las 0:0',
      0: 'Enero',
      1: 'Febrero',
      2: 'Marzo',
      3: 'Abril',
      4: 'Mayo',
      5: 'Junio',
      6: 'Julio',
      7: 'Agosto',
      8: 'Septiembre',
      9: 'Octubre',
      10: 'Noviembre',
      11: 'Diciembre',
    },
    DIGEST: {
      TOTAL: 'total',
      CODE: 'Localizador: __CODE__',
      PAX_SINGULAR: '__PAX__ persona',
      PAX_PLURAL: '__PAX__ personas',
      PAX: '__PAX__',
      SINCE: 'Precio total',
      FREE: 'Gratis',
      CANCELLED: 'Cancelada el ',
      CANCELLATION: 'Cancela gratis hasta el',
      BOOKING_NOT_CANCELLABLE: 'Reserva no cancelable',
    },

    EXPIRED: {
      TITLE: 'Actividad caducada',
      TEXT: 'Tu fecha de actividad es anterior a la fecha actual',
      BUTTON: null,
      DIALOG_TEXT: 'Esta actividad ha caducado',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    FEATURED_INFO: {
      TITLE: 'Atención al cliente',
      TEXT: 'Esta actividad es organizada por __NAME__. Puedes contactar con su servicio de atención al cliente a través de:',
      CUSTOMER_PROVIDER: 'Atención al cliente proveedor',
      CUSTOMER_TITLE: 'Si lo prefieres, puedes contactar con minube enviando un e-mail a reservas@minube.com',
    },
    FOOTER: {
      TEXT: '© minube 2007-2019 la web social de viajes',
    },

    IMPORTANT: {
      TITLE: 'Importante',
    },
    MEETING_POINT: {
      TITLE: 'Punto de encuentro',
    },
    MINUBE: {
      LOGO: 'https://imgs-akamai.mnstatic.com/tools/privateArea/logo_minube_color.svg',
      NAME: 'PRS Marketing Services S.L.',
      ADDRESS: 'Calle Loeches, 65.9',
      CITY: 'Alcorcón, Madrid',
      COUNTRY: 'España',
      ZIP: '28925',
      WEB: 'https://www.minube.com',
      EMAIL: 'info@minube.com',
      PHONE: '914 85 55 78',
      TAX_ID: 'B83737064',
    },
    NEWBOOKING: {
      TITLE: '¿Quieres realizar otra reserva?',
      TEXT: 'Puedes seleccionar otra fecha para realizar la actividad haciendo click aquí.',
      BUTTON: 'Reservar otro día',
    },
    NOTCANCELLABLE: {
      TITLE: 'Plazo de cancelación agotado',
      TEXT: 'Ha terminado el plazo que tenias para la cancelación gratuita',
      BUTTON: null,
      DIALOG_TEXT: 'Ya no puedes cancelar la actividad',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    VOUCHER: {
      TITLE: '__NAME__, tu reserva se ha realizado con éxito',
      TEXT: 'A continuación te mostramos los detalles de tu compra y la política de cancelación. Al cancelar tu reserva te enviaremos un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Imprimir',
      DIALOG_TEXT: null,
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: null,
      DETAILS_TITLE: 'Detalles de tu reserva',
      DEFAULT_LINK: 'Ver en el mapa',
    },
    CANCELLATION_MODAL: {
      TITLE: '¿Por qué quieres cancelar tu reserva?',
      BUTTON: 'Cancelar la reserva',
    },
    ERRORS: {
      ERROR_404: 'Página no encontrada',
      ERROR_404_SUBTITLE: 'La página a la que intentas acceder no está disponible actualmente.',
      ERROR_520: 'Se ha producido un error',
      ERROR_520_SUBTITLE: 'Por favor, ponte en contacto con nosotros a través de nuestro tlf o email.',
    },
  },
  'en-EN': {
    AND: 'and',
    BILLING: {
      DATA: 'Monthly Self-Invoice',
      INVOICE: 'Reservation Breakdown',
      BILL: 'Billing number',
      DATE_RANGE: 'Billing period',
      DATE: 'Date of isssue',
      FEE: 'Commission',
      NOTES: 'Billing by the recipient',
      TOTAL: 'Total',
      TAXES: 'Taxes 21%',
      COMMISSION: 'Commission of',
      COLUMNS: {
        DESCRIPTION: 'Concept',
        NIU: 'ID',
        TOTAL: 'Total',
        MINUBE_FEE: 'Minube Commission',
        TOTAL_NET: 'Net Total',
      },
    },
    CANCELLATION: {
      TITLE: 'Cancellation of your reservation',
      TEXT: 'Below we show you the details of your purchase and '
            + 'the cancellation policy. Upon cancelling your reservation we will send you'
            + ' an email confirming that the cancellation was successful.',
      BUTTON: 'Cancel booking',
      DIALOG_TEXT: 'Free cancellation until ',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: '#0e908d',
    },
    CANCELLATION_LEGAL: {
      TITLE: 'Cancellation Policy',
      TEXT: 'You may check the cancellation period for your booking in your purchase summary above. If your booking qualifies for free cancellation and you are in the indicated period you will receive a 100% refund of your purchase. If the period for free cancellation has ended, your purchase cannot be refunded. <br>For more information please check our <a href="https://www.minube.net/terms-use">Cancellation Policy.</a>.',
    },
    CANCELLED: {
      TITLE: 'Activity cancelled',
      TEXT: 'Your activity has already been cancelled',
      BUTTON: null,
      DIALOG_TEXT: 'Booking definitively cancelled on',
      DIALOG_DATE_CANCELLED: true,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },
    CONFIRMATION: {
      TEXT_OK: 'We have sent to __EMAIL__ the confirmation of your reservation and all the information so that you always have it available. If you cant find it, check your spam folder.',
      TITLE_OK: 'Reservation completed successfully',
      TITLE_CANCEL: 'Cancellation of the activity',
      TEXT_CANCEL: 'Below we show the details of your reservation and the cancellation policy. If you cancel the reservation we will send you an email with all the confirmation of the cancellation.',
      BUTTON: 'See my reservation',
    },
    CONFIRMATION_EXPIRED: {
      TITLE_OK: 'Sorry, this reservation has expired',
      TEXT_OK: 'The deadline to confirm your reservation has ended because this activity has already taken place.',
    },
    COUNTER_PERSON: 'People',
    COUNTER_ADULT: 'Adults',
    COUNTER_CHILD: 'Children',
    COUNTER_BABY: 'Babies',
    COUNTER_SENIOR: 'Retirees',
    COUNTER_GROUP: 'Groups',
    COUNTER_STUDENT: 'Students',
    COUNTER_VEHICLE: 'Vehicles',
    COUNTER_HANDICAPPED: 'Handicapped',
    COUNTER_OTHER: 'Others',
    COUNTER_SINGULAR_PERSON: 'Person',
    COUNTER_SINGULAR_ADULT: 'Adult',
    COUNTER_SINGULAR_CHILD: 'Child',
    COUNTER_SINGULAR_BABY: 'Baby',
    COUNTER_SINGULAR_SENIOR: 'Retiree',
    COUNTER_SINGULAR_GROUP: 'Group',
    COUNTER_SINGULAR_STUDENT: 'Student',
    COUNTER_SINGULAR_VEHICLE: 'Vehicle',
    COUNTER_SINGULAR_HANDICAPPED: 'Disabled',
    COUNTER_SINGULAR_OTHER: 'Other',

    DATES: {
      COMPLETE: '__DAY__ __MONTH__  __YEAR__ at __HOUR__:__MINUTE__',
      MONTH_YEAR: '__MONTH__, __YEAR__',
      TIME_NULL: ' at 0:0',
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    },
    DIGEST: {
      TOTAL: 'total',
      CODE: 'Tracking number: __CODE__',
      PAX_SINGULAR: '__PAX__ person',
      PAX_PLURAL: '__PAX__ people',
      PAX: '__PAX__',
      SINCE: 'Total price',
      FREE: 'Free',
      CANCELLED: ' Cancelled on ',
      CANCELLATION: 'Free cancellation until',
      BOOKING_NOT_CANCELLABLE: 'Reservation non-cancellable',
    },
    EXPIRED: {
      TITLE: 'Expired activity',
      TEXT: 'Your activity date is earlier than the current date',
      BUTTON: null,
      DIALOG_TEXT: 'This activity has expired',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },
    FEATURED_INFO: {
      TITLE: 'Customer support',
      TEXT: 'This activity is organised by __NAME__. You may contact their Customer Support Service via:',
      CUSTOMER_PROVIDER: 'Customer support',
      CUSTOMER_TITLE: 'You may contact our Customer Support Service sending an e-mail to reservas@minube.com or calling +34 914 855 578 from Monday to Friday between 08:00 and 18:00 CET',
    },
    FOOTER: {
      TEXT: '© minube 2007-2019 la web social de viajes',
    },
    IMPORTANT: {
      TITLE: 'Important',
    },
    MEETING_POINT: {
      TITLE: 'Meeting point',
    },
    MINUBE: {
      LOGO: 'https://imgs-akamai.mnstatic.com/tools/privateArea/logo_minube_color.svg',
      NAME: 'PRS Marketing Services S.L.',
      ADDRESS: 'Calle Loeches, 65.9',
      CITY: 'Alcorcón, Madrid',
      COUNTRY: 'España',
      ZIP: '28925',
      WEB: 'https://www.minube.com',
      EMAIL: 'info@minube.com',
      PHONE: '914 85 55 78',
      TAX_ID: 'B83737064',
    },
    NEWBOOKING: {
      TITLE: 'Do you want to make another reservation?',
      TEXT: 'You can select another date to do the activity by clicking here:',
      BUTTON: 'Book another day',
    },
    NOTCANCELLABLE: {
      TITLE: 'Cancellation deadline passed',
      TEXT: 'The free cancellation deadline is over',
      BUTTON: null,
      DIALOG_TEXT: 'You can no longer cancel the activity',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },
    VOUCHER: {
      TITLE: '__NAME__, your reservation has been made successfully',
      TEXT: 'Hello! Here you have all the details of your reservation so that you always have them at hand and the contact information of the organiser. Please check that all data is correct and... enjoy!',
      BUTTON: 'Print',
      DIALOG_TEXT: null,
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: null,
      DETAILS_TITLE: 'Your booking details',
      DEFAULT_LINK: 'See on the map',
    },
    CANCELLATION_MODAL: {
      TITLE: 'Why do you want to cancel your reservation?',
      BUTTON: 'Cancel reservation',
    },
    ERRORS: {
      ERROR_404: 'Page not found',
      ERROR_404_SUBTITLE: 'The page you are trying to access is currently unavailable.',
      ERROR_520: 'An error has occurred',
      ERROR_520_SUBTITLE: 'Please get in touch with us through our phone or email.',
    },
  },
  'br-BR': {
    AND: 'y',
    BILLING: {
      DATA: 'Monthly Self-Invoice',
      INVOICE: 'Reservation Breakdown',
      BILL: 'Billing number',
      DATE_RANGE: 'Billing period',
      DATE: 'Date of isssue',
      FEE: 'Commission',
      NOTES: 'Billing by the recipient',
      TOTAL: 'Total',
      TAXES: 'Taxes 21%',
      COMMISSION: 'Commission of',
      COLUMNS: {
        DESCRIPTION: 'Concept',
        NIU: 'ID',
        TOTAL: 'Total',
        MINUBE_FEE: 'Minube Commission',
        TOTAL_NET: 'Net Total',
      },
    },
    CANCELLATION: {
      TITLE: 'Cancelación de tu reserva',
      TEXT: 'A continuación te mostramos los detalles de tu compra y '
            + 'la política de cancelación. Al cancelar tu reserva te enviaremos'
            + ' un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Cancelar reserva',
      DIALOG_TEXT: 'Cancela gratis hasta  el ',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: '#0e908d',
    },
    CANCELLATION_LEGAL: {
      TITLE: 'Política de cancelación',
      TEXT: 'Puedes consultar el plazo para cancelar tu reserva en el resumen de tu compra que aparece arriba. Si tu reserva se puede cancelar gratuitamente y te encuentras dentro del plazo indicado, recibirás el reembolso del 100% de tu compra. Si te encuentras fuera de plazo para cancelar gratuitamente, tu compra no podrá ser reembolsada. Para más información, consulta nuestra Política de cancelación.',
    },
    CANCELLED: {
      TITLE: 'Actividad cancelada',
      TEXT: 'Tu actividad ya ha sido cancelada',
      BUTTON: null,
      DIALOG_TEXT: 'Reserva cancelada definitivamente el',
      DIALOG_DATE_CANCELLED: true,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },
    CONFIRMATION: {
      TEXT_OK: 'Hemos enviado a __EMAIL__ la confirmación de tu reserva y toda la información para que siempre la tengas disponible. Si no lo encuentras, revisa tu carpeta de spam.',
      TITLE_OK: 'Reserva finalizada con éxito',
      TITLE_CANCEL: 'Cancelación de la actividad',
      TEXT_CANCEL: 'A conuniación de mostramos los detalles de tu reserva y la política de cancelación. Si cancelas la reserva te enviaremos un email con toda la confirmación de la cancelación.',
      BUTTON: 'Ver mi reserva',
    },
    CONFIRMATION_EXPIRED: {
      TITLE_OK: 'Lo sentimos, esta reserva ha caducado',
      TEXT_OK: 'El plazo para confirmar tu reserva ha finalizado debido a que esta actividad ya ha tenido lugar.',
    },
    COUNTER_PERSON: 'Personas',
    COUNTER_ADULT: 'Adultos',
    COUNTER_CHILD: 'Niños',
    COUNTER_BABY: 'Bebés',
    COUNTER_SENIOR: 'Jubilados',
    COUNTER_GROUP: 'Grupos',
    COUNTER_STUDENT: 'Estudiantes',
    COUNTER_VEHICLE: 'Vehículos',
    COUNTER_HANDICAPPED: 'Minusválidos',
    COUNTER_OTHER: 'Otros',
    COUNTER_SINGULAR_PERSON: 'Persona',
    COUNTER_SINGULAR_ADULT: 'Adulto',
    COUNTER_SINGULAR_CHILD: 'Niño',
    COUNTER_SINGULAR_BABY: 'Bebé',
    COUNTER_SINGULAR_SENIOR: 'Jubilado',
    COUNTER_SINGULAR_GROUP: 'Grupo',
    COUNTER_SINGULAR_STUDENT: 'Estudiante',
    COUNTER_SINGULAR_VEHICLE: 'Vehículo',
    COUNTER_SINGULAR_HANDICAPPED: 'Minusválido',
    COUNTER_SINGULAR_OTHER: 'Otro',

    DATES: {
      COMPLETE: '__DAY__ de __MONTH__ de __YEAR__ a las __HOUR__:__MINUTE__',
      MONTH_YEAR: '__MONTH__ de __YEAR__',
      TIME_NULL: ' a las 0:0',
      0: 'Enero',
      1: 'Febrero',
      2: 'Marzo',
      3: 'Abril',
      4: 'Mayo',
      5: 'Junio',
      6: 'Julio',
      7: 'Agosto',
      8: 'Septiembre',
      9: 'Octubre',
      10: 'Noviembre',
      11: 'Diciembre',
    },
    DIGEST: {
      TOTAL: 'total',
      CODE: 'Localizador: __CODE__',
      PAX_SINGULAR: '__PAX__ persona',
      PAX_PLURAL: '__PAX__ personas',
      PAX: '__PAX__',
      SINCE: 'Precio total',
      FREE: 'Gratis',
      CANCELLED: 'Cancelada el ',
      CANCELLATION: 'Cancela gratis hasta el',
      BOOKING_NOT_CANCELLABLE: 'Reserva no cancelable',
    },

    EXPIRED: {
      TITLE: 'Actividad caducada',
      TEXT: 'Tu fecha de actividad es anterior a la fecha actual',
      BUTTON: null,
      DIALOG_TEXT: 'Esta actividad ha caducado',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    FEATURED_INFO: {
      TITLE: 'Atención al cliente',
      TEXT: 'Esta actividad es organizada por __NAME__. Puedes contactar con su servicio de atención al cliente a través de:',
      CUSTOMER_PROVIDER: 'Atención al cliente proveedor',
      CUSTOMER_TITLE: 'Si lo prefieres, puedes contactar con minube enviando un e-mail a reservas@minube.com',
    },
    FOOTER: {
      TEXT: '© minube 2007-2019 la web social de viajes',
    },

    IMPORTANT: {
      TITLE: 'Importante',
    },
    MEETING_POINT: {
      TITLE: 'Punto de encuentro',
    },
    MINUBE: {
      LOGO: 'https://imgs-akamai.mnstatic.com/tools/privateArea/logo_minube_color.svg',
      NAME: 'PRS Marketing Services S.L.',
      ADDRESS: 'Calle Loeches, 65.9',
      CITY: 'Alcorcón, Madrid',
      COUNTRY: 'España',
      ZIP: '28925',
      WEB: 'https://www.minube.com',
      EMAIL: 'info@minube.com',
      PHONE: '914 85 55 78',
      TAX_ID: 'B83737064',
    },
    NEWBOOKING: {
      TITLE: '¿Quieres realizar otra reserva?',
      TEXT: 'Puedes seleccionar otra fecha para realizar la actividad haciendo click aquí.',
      BUTTON: 'Reservar otro día',
    },
    NOTCANCELLABLE: {
      TITLE: 'Plazo de cancelación agotado',
      TEXT: 'Ha terminado el plazo que tenias para la cancelación gratuita',
      BUTTON: null,
      DIALOG_TEXT: 'Ya no puedes cancelar la actividad',
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: false,
      DIALOG_COLOR: '#da3f4b',
    },

    VOUCHER: {
      TITLE: '__NAME__, tu reserva se ha realizado con éxito',
      TEXT: 'A continuación te mostramos los detalles de tu compra y la política de cancelación. Al cancelar tu reserva te enviaremos un e-mail confirmando que la cancelación se ha realizado correctamente.',
      BUTTON: 'Imprimir',
      DIALOG_TEXT: null,
      DIALOG_DATE_CANCELLED: false,
      DIALOG_DATE_CANCELLATION: true,
      DIALOG_COLOR: null,
      DETAILS_TITLE: 'Detalles de tu reserva',
      DEFAULT_LINK: 'Ver en el mapa',
    },
    CANCELLATION_MODAL: {
      TITLE: '¿Por qué quieres cancelar tu reserva?',
      BUTTON: 'Cancelar la reserva',
    },
    ERRORS: {
      ERROR_404: 'Página no encontrada',
      ERROR_404_SUBTITLE: 'La página a la que intentas acceder no está disponible actualmente.',
      ERROR_520: 'Se ha producido un error',
      ERROR_520_SUBTITLE: 'Por favor, ponte en contacto con nosotros a través de nuestro tlf o email.',
    },
  },
};
