import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import appContext, { contextPropTypes } from '../../context/appContext';
import { D, C } from '../../common';
import '../../css/layouts/common.css';
import '../../css/layouts/Billing.css';
import setFormatDate from '../../components/modules/setFormatDate';
import setFormatMoney from '../../components/modules/setFormatMoney';
import {
  ProviderInfo,
} from '../../containers';
import {
  Text,
  fetch,
} from '../../components';

class Billing extends PureComponent {
  static propTypes = {
    hash: string.isRequired,
    context: contextPropTypes,
  };

  static defaultProps = {
    context: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const { _getData } = this;
    _getData();
  }

  componentDidUpdate() {
    const {
      data,
    } = this.state;
    if (data) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  _getData = () => {
    const { props: { hash }, _setDataState } = this;
    const service = `invoice/provider/${hash}/get`;
    fetch(service).then((response) => {
      const { props: { context: { onShowErrorModal } } } = this;
      if (response.errorStatus) {
        onShowErrorModal(response.errorStatus);
      } else {
        _setDataState(response);
      }
    });
  };

  _setDataState = (data) => {
    const { props: { context } } = this;
    const { language } = data;
    const l10n = D[language];
    const billingColumns = [
      l10n.BILLING.COLUMNS.DESCRIPTION,
      l10n.BILLING.COLUMNS.NIU,
      l10n.BILLING.COLUMNS.TOTAL,
      l10n.BILLING.COLUMNS.MINUBE_FEE,
      l10n.BILLING.COLUMNS.TOTAL_NET,
    ];
    context.onData('language', language);
    context.onData('l10n', l10n);
    this.setState({
      data,
      billingColumns,
    });
  };

  render() {
    const {
      state: {
        data,
        billingColumns,
      },
      props: { context: { language, l10n } },
    } = this;
    return (
      <div>
        { data && (
          <div className="billing">
            <div className="layout">
              <div className="providersInfo">
                <ProviderInfo
                  logo={l10n.MINUBE.LOGO}
                  name={l10n.MINUBE.NAME}
                  address={l10n.MINUBE.ADDRESS}
                  city={l10n.MINUBE.CITY}
                  country={l10n.MINUBE.COUNTRY}
                  zip={l10n.MINUBE.ZIP}
                  web={l10n.MINUBE.WEB}
                  email={l10n.MINUBE.EMAIL}
                  phone={l10n.MINUBE.PHONE}
                  taxId={l10n.MINUBE.TAX_ID}
                  className="left"
                />
                <ProviderInfo
                  logo={data.provider.logo}
                  name={data.provider.name}
                  address={data.provider.address}
                  city={data.provider.city}
                  country={data.provider.country}
                  zip={data.provider.zip}
                  web={data.provider.web}
                  email={data.provider.email}
                  phone={data.provider.phone}
                  taxId={data.provider.taxId}
                  className="right"
                />
              </div>
              <div className="data">
                <Text text={l10n.BILLING.DATA} typeText={`title ${C.FONTS.FONT_HEADLINE_6}`} />
                <div className="bill">
                  <Text text={l10n.BILLING.BILL} typeText={C.FONTS.FONT_SUBTITLE_3} />
                  <Text text={data.number.toString()} typeText={`info ${C.FONTS.FONT_BODY_3}`} />
                </div>
                <div className="date">
                  <Text text={l10n.BILLING.DATE_RANGE} typeText={C.FONTS.FONT_SUBTITLE_3} />
                  <Text
                    text={setFormatDate(data.billingInfo.date, 'MONTH_YEAR', language)}
                    typeText={`info ${C.FONTS.FONT_BODY_3}`}
                  />
                </div>
                <div className="date">
                  <Text text={l10n.BILLING.DATE} typeText={C.FONTS.FONT_SUBTITLE_3} />
                  <Text
                    text={setFormatDate(data.billingInfo.date, 'COMPLETE', language)}
                    typeText={`info ${C.FONTS.FONT_BODY_3}`}
                  />
                </div>
              </div>
              <div className="billData">
                <Text text={l10n.BILLING.INVOICE} typeText={`title ${C.FONTS.FONT_HEADLINE_6}`} />
                <div className="table">
                  <div className="row">
                    {Object.keys(billingColumns).map(key => (
                      <div key={`colBook${key.toString()}`} className="column">
                        <Text text={billingColumns[key]} typeText={C.FONTS.FONT_SUBTITLE_3} />
                      </div>
                    ))}
                  </div>
                  {Object.keys(data.bookings).map((key) => {
                    let totalWithLegacy = 0;
                    if (typeof (data.bookings[key].discount) === 'object' && data.bookings[key].discount.value > 0) {
                      if (data.bookings[key].discount.type === 'percentage') {
                        totalWithLegacy = (data.bookings[key].total.value * 100) / (100 - data.bookings[key].discount.value);
                      } else {
                        totalWithLegacy = data.bookings[key].total.value + data.bookings[key].discount.value;
                      }
                    } else {
                      totalWithLegacy = typeof (data.bookings[key].total) === 'object'
                        ? data.bookings[key].total.value : data.bookings[key].total;
                    }
                    let feeWithLegacy = 0;
                    if (typeof (data.bookings[key].discount) === 'object'  && data.bookings[key].discount.value > 0) {
                        if (data.bookings[key].discount.type === 'percentage') {
                            feeWithLegacy = data.bookings[key].fee.minube.value + ((data.bookings[key].total.value * 100) / (100 - data.bookings[key].discount.value) / data.bookings[key].discount.value);
                        } else {
                            feeWithLegacy = data.bookings[key].fee.minube.value + data.bookings[key].discount.value;
                        }
                    } else {
                        feeWithLegacy = typeof (data.bookings[key].fee) === 'object' ?
                          data.bookings[key].fee.minube.value : data.bookings[key].fee;
                    }
                    let provTotalWithLegacy = totalWithLegacy - feeWithLegacy;
                    return (
                      <div key={`rowBook${key.toString()}`} className="row">
                        <div className="column">
                          <Text
                            text={`${data.bookings[key].name} - ${data.bookings[key].locator}`}
                            typeText={C.FONTS.FONT_SUBTITLE_3}
                          />
                        </div>
                        <div className="column">
                          <Text text={data.bookings[key].activityId} typeText={C.FONTS.FONT_SUBTITLE_3} />
                        </div>
                        <div className="column">
                          <Text
                            text={setFormatMoney(totalWithLegacy.toFixed(2), language, data.billingInfo.accountInfo.currency)}
                            typeText={C.FONTS.FONT_SUBTITLE_3}
                          />
                        </div>
                        <div className="column">
                          <Text
                            text={setFormatMoney(feeWithLegacy.toFixed(2), language, data.billingInfo.accountInfo.currency)}
                            typeText={C.FONTS.FONT_SUBTITLE_3}
                          />
                        </div>
                        <div className="column">
                          <Text
                            text={setFormatMoney(provTotalWithLegacy.toFixed(2), language, data.billingInfo.accountInfo.currency)}
                            typeText={C.FONTS.FONT_SUBTITLE_3}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="column">
                      <Text text={l10n.BILLING.TOTAL} typeText={C.FONTS.FONT_SUBTITLE_3} />
                    </div>
                    <div className="column">
                      <Text text="" typeText={C.FONTS.FONT_SUBTITLE_3} />
                    </div>
                    <div className="column">
                      <Text text="" typeText={C.FONTS.FONT_SUBTITLE_3} />
                    </div>
                    <div className="column">
                      <Text text="" typeText={C.FONTS.FONT_SUBTITLE_3} />
                    </div>
                    <div className="column">
                      <Text
                        text={setFormatMoney(data.billingInfo.total, language, data.billingInfo.accountInfo.currency)}
                        typeText={C.FONTS.FONT_SUBTITLE_3}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="notes">
                <Text text={l10n.BILLING.NOTES} typeText={C.FONTS.FONT_BODY_2} />
              </div>
            </div>
          </div>
        )
      }
      </div>
    );
  }
}

export default appContext(Billing);
