import { node, shape } from 'prop-types';
import React, { createContext, Component } from 'react';

const AppContext = createContext();
const { Provider } = AppContext;

export const { Consumer } = AppContext;

class AppProvider extends Component {
  static propTypes = {
    children: node,
    state: shape({}),
  };

  static defaultProps = {
    children: undefined,
    state: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      language: '',
      cancellationReason: '',
      textOtherOption: '',
      onData: this.onData,
      showErrorModal: false,
      errorModalStatus: 0,
      languageBrowser: '',
      onShowErrorModal: this.onShowErrorModal,
      isCancellationSend: false,
      ...props.state,
    };
  }

  onData = (key, value) => {
    this.setState({ [key]: value });
  }

  onShowErrorModal = (value) => {
    // eslint-disable-next-line no-undef
    const langNavigator = navigator.language || navigator.userLanguage;
    const lang = langNavigator === null ? 'es-ES' : langNavigator;
    this.setState({
      showErrorModal: true,
      languageBrowser: lang,
      errorModalStatus: value,
    });
  }

  render() {
    const { onData, props: { children }, state } = this;
    return <Provider value={{ ...state, onData }}>{children}</Provider>;
  }
}

export default AppProvider;
