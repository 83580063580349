import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import '../../css/containers/ProviderInfo.css';
import { Image, Text } from '../../components';
import { C } from '../../common';

class ProviderInfo extends PureComponent {
  static propTypes = {
    logo: string,
    name: string,
    address: string,
    city: string,
    country: string,
    zip: string,
    web: string,
    email: string,
    phone: string,
    className: string,
    taxId: string
  };

  static defaultProps = {
    logo: undefined,
    name: undefined,
    address: undefined,
    city: undefined,
    country: undefined,
    zip: undefined,
    web: undefined,
    email: undefined,
    phone: undefined,
    className: undefined,
    taxId: undefined
  };

  render() {
    const {
      logo, name, address, city, country, zip, web, email, phone, className, taxId,
    } = this.props;
    return (
      <div className={`${className} provider`}>
        <div className="imageContainer">
          <Image className="picture" src={logo} />
        </div>
        <Text className="name" text={name} typeText={C.FONTS.FONT_HEADLINE_6} />
        <Text text={taxId} typeText={C.FONTS.FONT_BODY_3} />
        <Text text={address} typeText={C.FONTS.FONT_BODY_3} />
        <Text text={zip} typeText={C.FONTS.FONT_BODY_3} />
        <Text text={city} typeText={C.FONTS.FONT_BODY_3} />
        <Text text={country} typeText={C.FONTS.FONT_BODY_3} />
        <Text text={web} typeText={C.FONTS.FONT_BODY_3} />
        <Text text={email} typeText={C.FONTS.FONT_BODY_3} />
        <Text text={phone} typeText={C.FONTS.FONT_BODY_3} />
      </div>
    );
  }
}

export default ProviderInfo;
