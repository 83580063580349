import React from 'react';
import { Image } from '../../components';
import C from '../../common/constants';
import '../../css/containers/Header/simple.css';

const HeaderSimple = () => (
  <div className="headerSimple">
    <Image className="logo" src={C.LOGOS.GREY.SRC} />
  </div>
);

export default HeaderSimple;
