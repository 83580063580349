import React, { PureComponent } from 'react';
import { string } from 'prop-types';

class DefaultLink extends PureComponent {
  static propTypes = {
    text: string,
    link: string,
    className: string,
    target: string,
  };

  static defaultProps = {
    text: undefined,
    link: undefined,
    className: undefined,
    target: undefined,
  };

  render() {
    const {
      text, link, className, target,
    } = this.props;
    return (
      <a href={link} className={className} target={target}>
        {text}
      </a>
    );
  }
}

export default DefaultLink;
