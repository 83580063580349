export default {
  CANCELLATION: 'CANCELLATION',

  FONTS: {
    FONT_BODY_1: 'font_body1',
    FONT_BODY_2: 'font_body2',
    FONT_BODY_3: 'font_body3',
    FONT_BUTTON_1: 'font_button1',
    FONT_BUTTON_2: 'font_button2',
    FONT_CAPTION_1: 'font_caption1',
    FONT_CAPTION_2: 'font_caption2',
    FONT_HEADLINE_0: 'font_headline0',
    FONT_HEADLINE_1: 'font_headline1',
    FONT_HEADLINE_2: 'font_headline2',
    FONT_HEADLINE_3: 'font_headline3',
    FONT_HEADLINE_4: 'font_headline4',
    FONT_HEADLINE_5: 'font_headline5',
    FONT_HEADLINE_6: 'font_headline6',
    FONT_SUBTITLE_1: 'font_subtitle1',
    FONT_SUBTITLE_2: 'font_subtitle2',
    FONT_SUBTITLE_3: 'font_subtitle3',
    H1: 'font_h1',
  },
  COLORS: {
    SECONDARY: 'secondary',
    ERROR: 'error',
  },
  ICONS: {
    CALENDAR: 'https://imgs-akamai.mnstatic.com/tools/privateArea/calendar.png',
    CANCELLATION: 'https://imgs-akamai.mnstatic.com/tools/privateArea/tickbox_close.png',
    CIRCLE: 'https://imgs-akamai.mnstatic.com/tools/privateArea/ticket_circle.svg',
    CLOSE: 'https://imgs-akamai.mnstatic.com/tools/privateArea/button_close.png',
    CONFIRMATION_CHECK: 'https://imgs-akamai.mnstatic.com/tools/privateArea/Success_confirmation_check.svg',
    CONFIRMATION_ERROR: 'https://imgs-akamai.mnstatic.com/tools/privateArea/success_confirmation_error.svg',
    CODE: 'https://imgs-akamai.mnstatic.com/tools/privateArea/ic_pin.png',
    INFO: 'https://imgs-akamai.mnstatic.com/tools/privateArea/ic_helpcenter.png',
    INFO_GREY: 'https://imgs-akamai.mnstatic.com/tools/privateArea/ic_helpcenter.svg',
    MAIL: 'https://imgs-akamai.mnstatic.com/tools/privateArea/ic_mail.png',
    PAX: 'https://imgs-akamai.mnstatic.com/tools/privateArea/ic_person.png',
    PHONE: 'https://imgs-akamai.mnstatic.com/tools/privateArea/ic_phone.png',
  },

  LOGOS: {
    COLOR: {
      SRC: 'https://imgs-akamai.mnstatic.com/tools/privateArea/logo_minube_color.svg',
      TITLE: 'minube',
      ALT: 'minube',
    },
    GREY: {
      SRC: 'https://imgs-akamai.mnstatic.com/tools/privateArea/logo_minube_gris.svg',
      TITLE: 'minube',
      ALT: 'minube',
    },
  },

  FORM_TIMEOUT: 1000,

  VOUCHER: 'VOUCHER',
};
