import { StyleSheet } from 'react-native';

export default StyleSheet.create({

  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },

  bold: {

  },

  italic: {

  },
});
