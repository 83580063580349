import {
  arrayOf, bool, func, instanceOf, number, shape, string,
} from 'prop-types';

const CALENDAR = shape({});
const TIMETABLE = shape({});
const ADDON = shape({});
const DISCOUNT = shape({});

const PRICE = shape({
  value: number,
  symbol: string,
});

export default {
  ACTIVITY_THUMBNAIL: shape({
    backgroundColor: string,
    id: string,
    image: string,
    title: string,
  }),

  ADDON: shape({
    id: string,
    title: string,
    caption: string,
    exclude: bool,
    price: PRICE,
  }),

  BOOKING: {
    WHEN: shape({
      date: instanceOf(Date),
      timetable: arrayOf(number),
    }),
    ADDONS: shape({}),
    PEOPLE: shape({}),
    PAYMENT: shape({}),
  },

  DISCOVER: shape({
    dates: arrayOf(instanceOf(Date)),
    people: shape({}),
    search: string,
  }),

  HEADER_OPTION: shape({
    title: string,
    onPress: func,
  }),

  ITEM: shape({
    id: string,
    categories: arrayOf(string),
    calendars: arrayOf(CALENDAR),
    timetables: arrayOf(TIMETABLE),
    addons: arrayOf(ADDON),
    discounts: arrayOf(DISCOUNT),
    tags: arrayOf(string),
    languages: arrayOf(string),
    title: string,
    summary: string,
    description: string,
    image: string,
    gallery: arrayOf(string),
    adults: PRICE,
    childs: PRICE,
    babies: PRICE,
    people: arrayOf(number),
  }),

  L10N: shape({}),

  PRICE,

  SLIDER_MODEL: shape({
    endpoint: string,
  }),
};
