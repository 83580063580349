import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import '../../css/components/Image.css';

class Image extends PureComponent {
  static propTypes = {
    alt: string,
    className: string,
    src: string,
    title: string,
  };

  static defaultProps = {
    alt: undefined,
    className: undefined,
    src: undefined,
    title: undefined,
  };

  render() {
    const {
      alt, className, src, title,
    } = this.props;
    return (
      <img alt={alt} className={className} src={src} title={title} />
    );
  }
}

export default Image;
