import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import appContext, { contextPropTypes } from '../../context/appContext';
import { D, C } from '../../common';
import '../../css/layouts/common.css';
import '../../css/layouts/Invoices.css';
import setFormatDate from '../../components/modules/setFormatDate';
import setFormatMoney from '../../components/modules/setFormatMoney';
import getCompletedPrices from '../../components/modules/getCompletedPrices';
import {
  ProviderInfo,
} from '../../containers';
import {
  Text,
  fetch,
} from '../../components';

class UserInvoice extends PureComponent {
  static propTypes = {
    hash: string.isRequired,
    context: contextPropTypes,
  };

  static defaultProps = {
    context: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const { _getData } = this;
    _getData();
  }

  componentDidUpdate() {
    const {
      data,
    } = this.state;
    if (data) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  _getData = () => {
    const { props: { hash }, _setDataState } = this;
    const service = `invoice/${hash}/get`;
    fetch(service).then((response) => {
      const { props: { context: { onShowErrorModal } } } = this;
      if (response.errorStatus) {
        onShowErrorModal(response.errorStatus);
      } else {
        _setDataState(response);
      }
    });
  };

  _setDataState = (data) => {
    const { props: { context } } = this;
    const { language } = data;
    const l10n = D[language];
    const billingColumns = [
      l10n.BILLING.COLUMNS.DESCRIPTION,
      l10n.BILLING.COLUMNS.TOTAL,
    ];
    context.onData('language', language);
    context.onData('l10n', l10n);
    this.setState({
      data,
      billingColumns,
    });
  };

  render() {
    const {
      state: {
        data,
        billingColumns,
      },
      props: { context: { language, l10n } },
    } = this;
    let total = 0;
    let taxes = 0;

    return (
      <div>
        { data && (
          <div className="billing">
            <div className="layout">
              <div className="providersInfo">
                <ProviderInfo
                  logo={l10n.MINUBE.LOGO}
                  name={l10n.MINUBE.NAME}
                  address={l10n.MINUBE.ADDRESS}
                  city={l10n.MINUBE.CITY}
                  country={l10n.MINUBE.COUNTRY}
                  zip={l10n.MINUBE.ZIP}
                  web={l10n.MINUBE.WEB}
                  email={l10n.MINUBE.EMAIL}
                  phone={l10n.MINUBE.PHONE}
                  taxId={l10n.MINUBE.TAX_ID}
                  className="left"
                />
                { data.agency !== undefined && (
                  <ProviderInfo
                    logo={data.agency.logo !== undefined ? data.agency.logo : null}
                    name={data.agency.name !== undefined ? data.agency.name : null}
                    address={data.agency.address !== undefined
                      ? `${data.agency.address.street}, ${data.agency.address.number}`
                      : null
                    }
                    city={data.agency.address !== undefined ? data.agency.address.city : null}
                    country={data.agency.address !== undefined ? data.agency.address.country : null}
                    zip={data.agency.address !== undefined ? data.agency.address.zip : null}
                    web={data.agency.web !== undefined ? data.agency.web : null}
                    email={data.agency.email !== undefined ? data.agency.email : null}
                    phone={data.agency.phone !== undefined ? data.agency.phone : null}
                    taxId={data.agency.taxId !== undefined ? data.agency.taxId : null}
                    className="right"
                  />
                )}
              </div>
              <div className="data">
                <div className="bill">
                  <Text text={l10n.BILLING.BILL} typeText={C.FONTS.FONT_SUBTITLE_3} />
                  <Text text={data.number.toString()} typeText={C.FONTS.FONT_BODY_3} />
                </div>
                <div className="date">
                  <Text text={l10n.BILLING.DATE} typeText={C.FONTS.FONT_SUBTITLE_3} />
                  <Text
                    text={setFormatDate(data.created, 'COMPLETE', language)}
                    typeText={C.FONTS.FONT_BODY_3}
                  />
                </div>
              </div>
              <div className="billData">
                <div className="table">
                  <div className="row">
                    {Object.keys(billingColumns).map(key => (
                      <div key={`colBook${key.toString()}`} className="column">
                        <Text text={billingColumns[key]} typeText={C.FONTS.FONT_SUBTITLE_3} />
                      </div>
                    ))}
                  </div>
                  {data.bookings.map((booking) => {
                    const prices = getCompletedPrices(booking.total.value, booking.fee.minube.value, booking.discount);
                    total += prices.userPrices.withTaxes;
                    const date = `${booking.dates[0].date.slice(0, 4)}-`
                      + `${booking.dates[0].date.slice(4, 6)}-${booking.dates[0].date.slice(6, 8)}`
                      + `T${booking.dates[0].time}`;

                    return (
                      <div key={`rowBook${booking.id.toString()}`} className="row">
                        <div className="column alignLeft">
                          <Text
                            text={booking.experience}
                            typeText={C.FONTS.FONT_SUBTITLE_3}
                          />
                          <Text
                            text={booking.locator}
                            typeText={C.FONTS.FONT_SUBTITLE_3}
                          />
                          <Text
                            text={setFormatDate(date, 'COMPLETE', language)}
                            typeText={C.FONTS.FONT_SUBTITLE_3}
                          />
                        </div>
                        <div className="column alignRight">
                          <Text
                            text={setFormatMoney(
                              prices.userPrices.withTaxes,
                              language,
                              booking.total.currency,
                            )}
                            typeText={C.FONTS.FONT_SUBTITLE_3}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="column noBorder alignRight">
                      <Text text={l10n.BILLING.TOTAL} typeText={C.FONTS.FONT_SUBTITLE_3} />
                    </div>
                    <div className="column alignRight">
                      { <Text
                        text={setFormatMoney(total, language, data.bookings[0].total.currency)}
                        typeText={C.FONTS.FONT_SUBTITLE_3}
                      /> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default appContext(UserInvoice);
