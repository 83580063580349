import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { Text } from '../../components';
import { IconText } from '../index';
import { C, D } from '../../common';
import '../../css/containers/FeaturedInfo.css';
import appContext, { contextPropTypes } from '../../context/appContext';

class FeaturedInfo extends PureComponent {
  static propTypes = {
    providerName: string,
    textPhone: string,
    textMail: string,
    context: contextPropTypes,
  };

  static defaultProps = {
    providerName: undefined,
    textPhone: undefined,
    textMail: undefined,
    context: undefined,
  };

  render() {
    const {
      providerName,
      textPhone,
      textMail,
      context: { language },
    } = this.props;
    const textInfo = D[language].FEATURED_INFO.TEXT.replace('__NAME__', providerName);
    return (
      <div className="featuredInfo">
        <div className="container">
          <div
            className="pictureYellow"
          />
          <div
            className="picturePrint"
          />
          <Text
            typeText={C.FONTS.FONT_HEADLINE_6}
            text={D[language].FEATURED_INFO.TITLE}
          />
          <Text
            typeText={C.FONTS.FONT_BODY_2}
            text={textInfo}
          />
          <IconText className="mail" typeText={C.FONTS.FONT_BODY_2} icon={C.ICONS.MAIL} text={textMail} />
          <IconText className="phone" typeText={C.FONTS.FONT_BODY_2} icon={C.ICONS.PHONE} text={textPhone} />
          <Text
            typeText={C.FONTS.FONT_CAPTION_2}
            text={D[language].FEATURED_INFO.CUSTOMER_TITLE}
          />
        </div>
      </div>
    );
  }
}

export default appContext(FeaturedInfo);
