import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import '../../css/components/Info.css';
import { C } from '../../common';
import { Text } from '../../components';

class Info extends PureComponent {
  static propTypes = {
    dialogText: string,
    dialogColor: string,
    dialogBck: string,
    extraClass: string,
    color: string,
  };

  static defaultProps = {
    dialogText: undefined,
    dialogColor: undefined,
    dialogBck: undefined,
    extraClass: undefined,
    color: undefined,
  };

  render() {
    const {
      dialogText,
      dialogColor,
      dialogBck,
      extraClass,
      color,
    } = this.props;
    return (
      <div className={`info ${extraClass}`} style={{ backgroundColor: dialogBck, color }}>
        <Text
          className="infoText"
          text={dialogText}
          typeColor={dialogColor}
          typeText={C.FONTS.FONT_BODY_2}
        />
      </div>
    );
  }
}

export default Info;
