import React, { PureComponent } from 'react';
import {
  arrayOf,
  shape,
  string,
  number,
} from 'prop-types';
import Map from '../Map/Map';
import { C, D } from '../../common';
import { DefaultLink, Text } from '../../components';
import { getMapUrl } from '../../components/modules';
import '../../css/containers/MeetingPoint.css';
import appContext, { contextPropTypes } from '../../context/appContext';


class MeetingPoint extends PureComponent {
  static propTypes = {
    latitude: number,
    longitude: number,
    address: string,
    description: string,
    context: contextPropTypes,
    locations: arrayOf(shape({
      latitude: string,
      longitude: string,
    })),
  };

  static defaultProps = {
    latitude: undefined,
    longitude: undefined,
    address: undefined,
    description: undefined,
    locations: undefined,
    context: undefined,
  };

  render() {
    const {
      latitude,
      longitude,
      address,
      description,
      locations,
      context: { language },
    } = this.props;
    return (
      <div className="meetingPoint">
        <div className="container">
          <Text
            typeText={C.FONTS.FONT_HEADLINE_5}
            text={D[language].MEETING_POINT.TITLE}
          />
          <Map
            latitude={latitude}
            longitude={longitude}
            locations={locations}
          />
          <Text
            typeText={`${C.FONTS.FONT_SUBTITLE_2} titleAdress`}
            text={address}
          />
          <Text
            typeText={`${C.FONTS.FONT_BODY_1} subtitleAdress`}
            text={description}
          />
          <DefaultLink
            text={D[language].VOUCHER.DEFAULT_LINK}
            target="_blank"
            link={getMapUrl(latitude, longitude)}
            className={`${C.FONTS.FONT_BUTTON_1} defaultLink`}
          />
        </div>
      </div>
    );
  }
}

export default appContext(MeetingPoint);
