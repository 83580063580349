export default {
  primary: '#10c0bc',
  secondary: '#0E908D',
  error: '#EF5160',
  hotels: '#F69A37',
  alternative: '#A66ECC',
  disabled: '#CDD2D4',
  light: '#f4f4f4',
  default: '#4a4a4a',
  grey: '#707070',
  lightGrey: '#9b9b9b',
  white: '#FFFFFF',
  black: '#000000',
};
