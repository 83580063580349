import React, { PureComponent } from 'react';
import { C, D } from '../../common';
import { Image, Text } from '../../components';
import '../../css/containers/Footer.css';
import appContext, { contextPropTypes } from '../../context/appContext';

class Footer extends PureComponent {
  static propTypes = {
    context: contextPropTypes,
  };

  static defaultProps = {
    context: undefined,
  };

  render() {
    const { context: { language } } = this.props;
    return (
      <div className="footer">
        <Image src={C.LOGOS.GREY.SRC} />
        <Text
          text={D[language].FOOTER.TEXT}
          className="text"
          typeText={C.FONTS.FONT_CAPTION_2}
        />
      </div>
    );
  }
}

export default appContext(Footer);
