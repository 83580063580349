export default (amount, language, currency) => {
  const formatter = new Intl.NumberFormat(
    language,
    {
      style: 'currency',
      currency,
    },
  );
  return formatter.format(amount);
};
