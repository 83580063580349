import React, { PureComponent } from 'react';
import {
  bool, node, func, string,
} from 'prop-types';
import ReactDOM from 'react-dom';
import { Icon } from '../../components';
import C from '../../common/constants';
import '../../css/components/Modal.css';

class Modal extends PureComponent {
  static propTypes = {
    isOpen: bool,
    children: node,
    onClose: func,
    className: string,
  };

  static defaultProps = {
    isOpen: undefined,
    children: undefined,
    onClose: undefined,
    className: undefined,
  };

  render() {
    const {
      isOpen,
      onClose,
      children,
      className,
    } = this.props;
    return isOpen ? ReactDOM.createPortal(
      <div className="modalBackground">
        <div className="modal">
          <div className={`content ${className}`}>
            {children}
            <div className="modalButton" role="presentation" onClick={onClose}>
              <Icon className="closeIcon" url={C.ICONS.CLOSE} />
            </div>
          </div>
        </div>
      </div>,
      document.getElementById('modal'),
    )
      : null;
  }
}

export default Modal;
