import React, { PureComponent } from 'react';
import { shape, string } from 'prop-types';
import TitleText from '../TitleText';
import { Icon } from '../../components';
import { C, D } from '../../common';
import '../../css/containers/Status.css';
import appContext, { contextPropTypes } from '../../context/appContext';


class Status extends PureComponent {
  static propTypes = {
    dictionaryKey: string.isRequired,
    data: shape({}).isRequired,
    context: contextPropTypes,
  };

  static defaultProps = {
    context: undefined,
  };

  render() {
    const {
      dictionaryKey,
      data,
      context: { language },
    } = this.props;
    const textWithEmail = D[language][dictionaryKey].TEXT_OK.replace('__EMAIL__', data.user.email);
    const icon = dictionaryKey === 'CONFIRMATION' ? C.ICONS.CONFIRMATION_CHECK : C.ICONS.CONFIRMATION_ERROR;
    return (
      <div className="status">
        <Icon className="titleIcon" url={icon} />
        <TitleText
          textTitle={D[language][dictionaryKey].TITLE_OK}
          typeTextTitle="font_headline4 center"
          text={textWithEmail}
          typeText="font_body1 center"
        />
      </div>

    );
  }
}

export default appContext(Status);
