//const ENDPOINT = process.env.REACT_APP_IS_PRODUCTION === 'true' ? 'https://mapi.minube.com' : 'http://staging.mapi.minube.com';
const ENDPOINT = process.env.REACT_APP_ENV === 'production' ? 'https://mapi.minube.com' : 'http://staging.mapi.minube.com';

// @TODO: Adding prod vs dev
export default async (service, method, data) => {
  const options = {
    method: method || 'GET',
    headers: {
      Accept: 'application/json',
    },
  };
  if (data) {
    options.body = JSON.stringify(data);
    options.headers['Content-Type'] = 'application/json';
  }

  return fetch(`${ENDPOINT}/${service}`, options)
    .then(response => (response.status === 200 ? response.json() : {
      errorStatus: response.status,
    }))
    .catch(error => (error));
};
