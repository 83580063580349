export default (status, from, timelimit, dateNow) => {
  let key;
  const hoursToMiliseconds = 3600 * 1000;
  const timeBooking = new Date(from.formatted).getTime();
  const timeToCancel = timeBooking - (timelimit * hoursToMiliseconds);
  const timeNow = dateNow.getTime();

  if (status === 'cancelledByCustomer' || status === 'cancelledByUser') key = 'CANCELLED';
  else if (timeNow > timeBooking) key = 'EXPIRED';
  else if (timeNow > timeToCancel && timeNow < timeBooking) key = 'NOTCANCELLABLE';
  else if (timeNow < timeToCancel) key = 'CANCELLATION';
  return key;
};
