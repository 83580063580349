import React, { PureComponent } from 'react';
import { string } from 'prop-types';

class Icon extends PureComponent {
  static propTypes = {
    url: string,
    className: string,
  };

  static defaultProps = {
    url: undefined,
    className: undefined,
  };

  render() {
    const { className, url } = this.props;
    return (
      <div
        className={className}
        style={{ backgroundImage: `url(${url})` }}
      />
    );
  }
}

export default Icon;
